<!-- 
  飞行监控---无人机
 -->
<template>
    <div class="page-observe-fckernel-uav">
        <!-- 顶部标题栏 -->
        <Header @auto="auto" @TakeOverFlag="showTakeOver" :wsShow="wsShow" @control_ws="uav_control_ws"
            :uav-data="uav.control.data" :isSelectFlag="flag" :device="uav.control.device" :level="level"
            @changeF="$emit('changeF')" @fn="uav_mqtt_fn"></Header>
        <div class="warn w440" v-if="warnShow">
            <div class="content" @click="loggerFlag = true">{{ warnMessage }}</div>
            <div class="close" @click="warnShow = false">关闭</div>
        </div>
        <!-- 日志信息 -->
        <Logger @exit="loggerFlag = false" v-if="loggerFlag && !isAirwayEdit" :list="uav.msg_list"
            @clear-msg="uav.msg_list = []"></Logger>
        <!-- <div class="nsetLeftBox" :class="{ collapse: collapseFlag }">
      <img @click="collapseFlagfn" v-if="collapseFlag" style="transform: rotate(180deg)"
        src="~@/assets/images/observe/fckernel/hs.png" class="icon-collapse nsetLeftBox_btn" />
      <img @click="collapseFlagfn" v-else src="~@/assets/images/observe/fckernel/collapse.png"
        class="icon-collapse nsetLeftBox_btn" />
      <div class="uav-list-header">
        <img class="uav-list-header__icon" src="~@/assets/images/uav_list_header.png" />
        <span class="uav-list-header__text">无人机列表</span>
      </div>
      <div class="uav-search">
        <el-input class="uav-search__input" clearable placeholder="请输入无人机名称/机构名称" v-model="uavSearchContent"
          v-on:keyup.enter.native="onUavSearch">
          <i slot="suffix" class="el-input__icon el-icon-search" style="color: rgba(123, 181, 213, 1)"></i>
        </el-input>
        <el-button class="uav-search__btn" @click="onUavSearch">搜索</el-button>
      </div>
      <UavList :class="uav.TakeOverFlag ? 'top0' : ''" @exit="flag = false" v-if="flag" @videoChange="videoChange"
        :containerStyle="{
          width: '400PX',
          height: '275px',
        }" :list="uav.list" @fn="uav_fn" @refresh="onUavSearch" />
        </div>-->

        <!-- -----------------------无人机列表面板---------------------------- -->
        <!-- <div class="collapse-container" v-show="vuaListHeader">
            <div class="img-con img1" @click="collapseFlagfn" v-if="collapseFlag" title="打开无人机列表面板">
                <img class="img" src="@/assets/img/i27.png" alt />
            </div>
            <div class="img-con img2" @click="collapseFlagfn" v-else title="关闭无人机列表面板">
                <img class="img" src="@/assets/img/i26.png" alt />
            </div>
        </div> -->
        <div class="nsetLeftBox" :class="{ collapse: collapseFlag }" v-show="false">
            <div class="uav-list-header-new">
                <span class="title">无人机列表</span>
            </div>
            <div class="uav-search">
                <el-input class="uav-search__input" clearable placeholder="请输入无人机名称/机构名称" v-model="uavSearchContent"
                    v-on:keyup.enter.native="onUavSearch"></el-input>
                <el-button class="uav-search__btn" @click="onUavSearch" icon="el-icon-search"></el-button>
            </div>
            <!-- 无人机列表 -->
            <UavList :class="uav.TakeOverFlag ? 'top0' : ''" @exit="flag = false" v-if="flag" @videoChange="videoChange"
                id="uavListId" :containerStyle="{ width: '400PX', height: '275px' }" :list="uav.list" @fn="uav_fn_click"
                @refresh="onUavSearch"></UavList>
        </div>
        <!-- -----------------------无人机列表面板结束---------------------------- -->

        <component v-if="uav.control.mount" :is="uav.control.mount.component" :PoliceKeyCode="uav.control.device && uav.control.device.deviceHardId
            " @directive="uav_mount_directive" @close="uav_change_mount" :deviceid="uav.control.device"
            @showCenter="(val) => (showCenter = val)" />

        <!-- 底部信息 -->
        <Control :device="uav.control.device" :wsShow="wsShow" :offline="uav.offline" :data="uav.control.channelData"
            v-if="ControlFlag || (uav.control.data && !isAirwayEdit)" :uav-battery="uav.control.battery"
            :uav-data="uav.control.data" @fn="(data, type) => uav_mqtt_fn(data, type)"
            @fun="(data) => uav_send_ws(data)"></Control>

        <AirwayEdit :flag="false" @add="airway_add" v-if="isAirwayEdit" :isShow="false" @quit="AirwayQuit"></AirwayEdit>
        <!-- -->

        <!-- ---------------------- 视频窗口开始 ---------------------- -->
        <div class="videoBox uav_videoBox" ref="uav_videoBox">
            <!-- uav_move_directive -->
            <FloatPlayer @directiveFn="uav_move_directive" @imgUrl="getimgUrl" :device="uav.control.device"
                v-for="(video, index) in uav.videos" :uav-data="uav.control.data" @videoItemFn="videoItemFn(index)"
                @close="uav_hide_video" @fn="uav_mqtt_fn" :key="video.id" :data="video" :class="`_${index}`"
                :videoItem="videoItem" :index="index" :showCenter="showCenter" />
        </div>
        <!-- ---------------------- 视频窗口结束 ---------------------- -->

        <!-- 创建任务面板 -->
        <TaskList :device="uav.control.device" :orgName="orgName" :taskstyle="collapseFlag" @Lsdom="Lsdom"
            @taskType="taskTypeFn" @iconShow="iconShow = true" ref="TaskListRef"
            @fn="(data, type) => uav_mqtt_fn(data, type)" @fun="(data) => uav_send_ws(data)" @craeteRoute="CraeteRoute"
            v-show="controlMenuFlag" @createTaskClick="createTaskClick"
            @airLineLibraryCompShowFlag="airLineLibraryPanelHandleShow"
            @taskListOpenUvaListPanel="taskListOpenUvaListPanel" @takeover="takeoverFromTaskList"></TaskList>
        <!-- 创建任务面板--- -->

        <!-- 任务库面板开始 -->
        <TaskLibrary class="task-library" :class="[taskLibraryShrinkFlag ? 'task-library-shrink' : '']"
            v-show="taskLibraryPanelShow" @createTaskComp="createTaskComp"
            :taskLibraryShrinkFlag="taskLibraryShrinkFlag"></TaskLibrary>
        <!-- 任务库面板结束 -->

        <!-- 创建常态、临时紧急任务面板开始 -->
        <CreateTask class="uav-create-task" v-show="createTaskCompPanelShow" :cateIdFlag="createTaskCompTitleFlag"
            @mainShow="createTaskCompMainShow"></CreateTask>
        <!-- 创建常态、临时紧急任务面板结束 -->

        <!-- 航线库面板开始 -->
        <AirLineLibrary class="airline-library" v-show="airLineLibraryPanelShow"
            :class="[airLineLibraryShrinkFlag ? 'task-library-shrink' : '']"
            :airLineLibraryShrinkFlag="airLineLibraryShrinkFlag"></AirLineLibrary>
        <!-- 航线库面板结束 -->

        <!-- 创建新航线弹框 -->
        <el-dialog title="航线设置2" :visible.sync="newLineDialogVisible" width="25%" :modal="false" :center="true"
            custom-class="create-new-line-dialog">
            <div class="main-body">
                <el-form ref="creatAirlineForm" :rules="rules" :model="createAirlineDialogForm" label-width="0"
                    label-position="top">
                    <el-form-item label="请输入航线名称" prop="name">
                        <el-input v-model="createAirlineDialogForm.name" placeholder="请输入航线名称"></el-input>
                    </el-form-item>
                    <el-form-item label="请选择执行方式" prop="actionWay">
                        <el-select v-model="createAirlineDialogForm.actionWay" placeholder="请选择执行方式"
                            @change="handle_actionWayChange">
                            <el-option label="无人机" value="uav"></el-option>
                            <el-option label="机巢" value="nest"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择机巢" v-show="nestShowFlag">
                        <el-select v-model="createAirlineDialogForm.nest" placeholder="请选择选择机巢">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="高度模式" prop="altitudeMode">
                        <el-select popper-class="popper-select-panel" v-model="createAirlineDialogForm.altitudeMode"
                            placeholder="请选择高度模式">
                            <el-option v-for="item in altitudeMode_list" :key="item.id" :label="item.name"
                                :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="跟随航线" prop="isFollowAirLine">
                        <el-radio style="margin-left: 10px" v-model="createAirlineDialogForm.isFollowAirLine"
                            :label="true">是</el-radio>
                        <el-radio v-model="createAirlineDialogForm.isFollowAirLine" :label="false">否</el-radio>
                    </el-form-item>
                    <!-- (1-500m) -->
                    <el-form-item label="全局飞行高度" prop="baseHeight">
                        <div class="body average-height-body">
                            <div class="btn btn1" @click="flyHeightClickHandle(-100)">-100</div>
                            <div class="btn btn2" @click="flyHeightClickHandle(-10)">-10</div>
                            <div class="btn btn3" @click="flyHeightClickHandle(-1)">-1</div>
                            <el-input size="small" v-model="createAirlineDialogForm.baseHeight" placeholder
                                @input="flyHeightInputHandle"></el-input>
                            <div class="btn btn4" @click="flyHeightClickHandle(1)">+1</div>
                            <div class="btn btn5" @click="flyHeightClickHandle(10)">+10</div>
                            <div class="btn btn6" @click="flyHeightClickHandle(100)">+100</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="全局飞行速度(1-20m/s)" prop="baseSpeed">
                        <div class="body body-base-speed">
                            <img class="img" src="@/assets/img/i79.png" alt @click="flySpeedClickHandle(-1)" />
                            <el-slider class="slider" v-model="createAirlineDialogForm.baseSpeed" :min="1"
                                :max="20"></el-slider>
                            <img class="img" src="@/assets/img/i80.png" alt @click="flySpeedClickHandle(1)" />
                            <el-input class="speed-input" size="small" v-model.number="createAirlineDialogForm.baseSpeed
            " placeholder></el-input>
                            <span class="unit">m/s</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="完成动作" prop="finishedAction">
                        <el-select popper-class="popper-select-panel" v-model="createAirlineDialogForm.finishedAction"
                            placeholder="请选择完成动作">
                            <el-option v-for="item in finishedAction_list" :key="item.id" :label="item.name"
                                :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="场景" prop="scene">
                        <el-select v-model="createAirlineDialogForm.scene" placeholder="请选择场景">
                            <el-option v-for="item in scene_list" :key="item.id" :label="item.name"
                                :value="item.name" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="createLineDialogCancel">取 消</el-button>
                <el-button type="primary" @click="createLineDialogSubmit">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 手动规划航线组件开始 -->
        <Manual class="manual-panel" v-if="manualPanelShow" :panelForm="createAirlineDialogFormCopy"
            :lablistL="lablistL" :noAutoPlan="true" @close="closeCreatePanel"
            @editCreateAriLinePanel="editCreateAriLinePanelClick"
            :class="[manualPlanShrinkFlag ? 'task-library-shrink' : '']" :manualPlanShrinkFlag="manualPlanShrinkFlag">
        </Manual>
        <!-- 手动规划航线组件结束 -->

        <!-- 各个控制面板  挂载、健康管理、图库工具栏 -->
        <ControlMenu class="control-menu" ref="ControlMenu" :wsShow="wsShow" :taskstyle="collapseFlag"
            :wrjType="uav.wrjType" :uav-data="uav.control.data" :device="uav.control.device" :healthData="healthData"
            :batteryData="uav.control.battery" :iconShow="iconShow" :taskType="taskType" :uav-mounts="uav.mounts"
            v-if="uav.control.device" :br3ClickedFlag="br3ClickedFlag" @showCenter="showLine" @imgUrl="getimgUrl"
            @clearId="clearIdFn" @Lsdom="Lsdom" :mount="uav.control.mounts"
            @uav-location="uav_show_location(uav.control.device)" @airway-display="uav_show_airway(uav.control.device)"
            @yxrz="LoggerFn" @fn="(data, type) => uav_mqtt_fn(data, type)" @fun="(data) => uav_send_ws(data)"
            @changeTask="onChangeTask" @gzPanelClose="gzPanelClose" @resetRightBar="br3ClickedFlag = -1"></ControlMenu>

        <!-- 请求控制权限 -->
        <Notice @close="uav.NoticeFlag = false" @handle="uav_jursdiction" class @qzjg="qzjg"
            @exit="uav.NoticeFlag = false" v-if="uav.NoticeFlag" :leftText="uav.NoticeData.leftText"
            :rightText="uav.NoticeData.rightText" :content="uav.NoticeData.content" :title="uav.NoticeData.title"
            :flag="true"></Notice>

        <!-- 接管记录 -->
        <TakeOver @exit="uav.TakeOverFlag = false" v-if="uav.TakeOverFlag"></TakeOver>
        <!-- 视频背景模式 -->
        <!-- <VideoTomap v-if="VideoTomapFlag && videoFlag && uav.videos.length > 0"></VideoTomap> -->
        <VideoTomap v-if="true"></VideoTomap>
        <el-dialog width="40%" :visible.sync="progressT">
            <el-progress :text-inside="true" :stroke-width="26" :percentage="jindu"></el-progress>
        </el-dialog>
        <div class="imgshowurl" v-if="imgshow">
            <div @click="imgshow = false" class="close">关闭</div>
            <img :src="imgshowurl" />
        </div>
        <!-- 侧边栏 0 无人机， 1 任务订单，2 视频墙 -->
        <SideBar :selectedFlag="0" class="side-bar"></SideBar>
    </div>
</template>

<script>
import QS from '@/components/PagerP1_New';

import { mapGetters } from 'vuex';
// 无人机列表组件
import UavList from './components/list';
// 视频播放器组件
import FloatPlayer from './components/float-playerfk';
import AirwayEdit from './components/airway-edit';

import data from './data';
import methods from './methods';
import Header from './components/header/index';
// 下面的油门图标
import Control from './components/control/index.vue';
// 创建任务面板
import TaskList from './components/taskList';
// 下面一键任务的小图标
import ControlMenu from './components/control/controlMenu';
import Notice from './components/control/components/notice';
import Logger from './components/control/logger/index';
import TakeOver from './components/control/components/takeOver';
import VideoTomap from './components/control/videoTomap';
// 侧边栏
import SideBar from '../../home/components/sideBar';
// 任务库面板
import TaskLibrary from './components/taskList/components/taskLibrary.vue';
// 航线库面板
import AirLineLibrary from './components/taskList/components/airLineLibrary.vue';
// 创建常态任务、临时紧急任务组件
import CreateTask from '@/components/command/flight-task/new-components/flyTask/createTask.vue';
// 航线规划面板
import Manual from '@/components/command/flight-task/new-components/airLine/manual/index.vue';
// 引用bus
import Bus from '@/assets/ligature.js';
import API from '@/api';
import _ from 'lodash';
import dayjs from 'dayjs'

export default {
    components: {
        QS,
        UavList,
        FloatPlayer,
        Header,
        Control,
        TaskList,
        ControlMenu,
        AirwayEdit,
        Notice,
        TakeOver,
        Logger,
        VideoTomap,
        SideBar,
        TaskLibrary,
        AirLineLibrary,
        CreateTask,
        Manual
    },
    data() {
        return {
            ...data,
            healthData: {},
            message: {},
            showCenter: false,
            h: 0,
            level: null, //M300信号等级
            warnShow: false, //异常消息弹窗
            warnMessage: '', //异常消息
            imgshow: false, //拍照图片展示
            imgshowurl: null,
            videoFlag: true,
            // 头部状态是否为展示状态
            flag: true,
            controlMenuFlag: true, //创建任务面板显隐控制
            loggerFlag: false,
            // 运行监测
            powerFlag: true,
            // 创建航线窗口
            isAirwayEdit: false,
            // 创建任务
            CreateTaskFlag: false,
            // 左下角切换视频
            VideoTomapFlag: false,
            // 下方展示数据面板
            ControlFlag: false,
            iconShow: false,
            taskType: '',
            videoItem: 0,
            collapseFlag: false,
            linevisible: false,
            takeOff: false,
            uavId: null, //无人机id
            progressT: false, //进度条弹框
            jindu: 0, //进度条进度
            uavSearchContent: '', //无人机搜索内容
            wsShow: false, //判断是走飞控中心链路还是地面站链路,
            vuaListHeader: false, //无人机列表头部收缩按钮面板
            taskLibraryPanelShow: false, //任务库面板显示与否
            airLineLibraryPanelShow: false, //航线库面板显示与否
            br3ClickedFlag: -1, // controlMenu 组件右侧顶部工具条点击标志
            createTaskCompTitleFlag: 0, // 创建常态任务、临时紧急任务标志 0 常态任务，1 临时紧急任务
            createTaskCompPanelShow: false, //创建常态任务、临时紧急任务组件显示隐藏标志
            manualPanelShow: false, //手动规划航线面板显示与否
            newLineDialogVisible: false, //前创建航线弹框选择选项
            createAirlineDialogForm: {
                name: '', // 航线名称
                actionWay: '', //执行方式 无人机 uav，机巢 nest
                nest: '', //机巢，执行方式选择机巢时候有效
                scene: '', //场景
                createLineManualOrAuto: 1, // 创建方式，手动创建 0， 自动创建 1
                // altitudeMode: 'relativeToGround', //高度模式，默认相对高度
                altitudeMode: '', //高度模式，默认相对高度
                // finishedAction: 'GoHome', //返航模式 默认分回到Home点
                finishedAction: '', //返航模式 默认分回到Home点
                isFollowAirLine: true, //跟随航线模式，布尔类型，默认为true；当为true时，生效航线高度、速度设置，同时禁止编辑航点速度；当为false时，生效高度、航点速度。
                baseHeight: 100, //全局高度
                baseSpeed: 5 //全局速度
            }, //弹框表单
            createAirlineDialogFormCopy: {},
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入航线名称',
                        trigger: 'blur'
                    }
                ]
            }, //弹窗表单校验
            scene_list: [
                { name: '巡查', id: 1 },
                { name: '预警', id: 2 },
                { name: '侦察', id: 3 },
                { name: '服务', id: 4 },
                { name: '救援', id: 5 },
                { name: '处置', id: 6 },
                { name: '打击', id: 7 },
                { name: '宣传', id: 8 },
                { name: '物流', id: 9 }
            ],
            nestShowFlag: false,
            taskLibraryShrinkFlag: false, //任务库收缩标志
            airLineLibraryShrinkFlag: false, //航线库收缩标志
            manualPlanShrinkFlag: false, //手动规划航线收缩标志
            orgName: '',
            altitudeMode_list: [
                { name: '相对高度', id: 1, value: 'relativeToGround' },
                { name: '绝对高度', id: 2, value: 'absolute' }
            ], //高度模式
            finishedAction_list: [
                { name: '返回到Home点', id: 1, value: 'GoHome' },
                { name: '悬停，无动作', id: 2, value: 'Hover' },
                { name: '原地降落', id: 3, value: 'AutoLand' }
            ] //返航模式
        };
    },
    inject: ['g_cesium_layer'],
    provide() {
        return {
            uav_This: this,
            findList: (search) => {
                if (search) {
                    this.uav_list(search);
                } else {
                    this.uav_list(search);
                    // this.uav_mountList(data.name);
                }
            },
            uav_mqtt_fn: this.uav_mqtt_fn,
            uav: this.uav
        };
    },
    watch: {
        healthData: function (newval, old) {
            // console.log(newval, old, 'newvalnewvalnewvalnewvalnewval');
            if (JSON.stringify(old) == '{}') {
                for (let i in newval) {
                    if (newval[i].warningLevel != 'NORMAL') {
                        if (this.taskId) {
                            this.message.taskId = this.taskId;
                        }
                        this.message = {
                            deviceId: this.uav.control.device.id,
                            exceptionTitle: newval[i].title,
                            exceptionTypeSign: i
                        };
                        // console.log(this.message, 'message222');
                        this.addMessage();
                    }
                }
            }
            for (let i in newval) {
                if (newval[i].warningLevel != 'NORMAL') {
                    if (old[i] && newval[i].code != old[i].code) {
                        if (this.taskId) {
                            this.message.taskId = this.taskId;
                        }
                        this.message = {
                            deviceId: this.uav.control.device.id,
                            exceptionTitle: newval[i].title,
                            exceptionTypeSign: i
                        };
                        // console.log(this.message, 'message1111');
                        this.addMessage();
                    }
                }
            }
        },
        data: function (vla, old) { },
        'uav.control.device': function (val) {
            this.uav.msg_list = [];
        }
    },
    computed: {
        ...mapGetters(['user_info']),
        taskId() {
            return this.$store.state.fckernel.taskId;
        },
        uav_mounts() {
            let { mounts } = this.uav.control;
            return mounts
                .map((item) => {
                    let find_item = this.mount.list.find((m) => m.name === item.gimbalName);
                    return find_item || undefined;
                })
                .filter((item) => item);
        }
    },
    created() {
        Bus.$on('remove_model', () => {
            // 删除飞控 无人机 模型和航线
            // 搜索关键字：删除飞控模型
            console.log('删除飞控 无人机 模型和航线...fackenral');
            this.uav_remove_model(this.uav.control.device);
        });
        // 等待航线上传成功在执行
        // 判断是否以一键任务
        Bus.$on('off_take', () => {
            this.takeOff = true;
        });
    },
    mounted() {
        console.log(this.mount, 'mount');
        this.getDeviceInfoByTaskId()

        // setTimeout(() => {
        //     this.uav_fn_click(4, {
        //     "id": 824,
        //     "deviceName": "UATAIR-20550",
        //     "deviceHardId": "MMC301R22208068",
        //     "deviceSerial": null,
        //     "organizationId": null,
        //     "goodsId": null,
        //     "organizationName": null,
        //     "anotherName": null,
        //     "status": null,
        //     "createAt": "2022-11-03 11:22:58",
        //     "updatedAt": "2024-01-15 17:05:38",
        //     "isNet": 1,
        //     "model": "",
        //     "cateId": 3,
        //     "cateName": "amp",
        //     "image": null,
        //     "reportStatus": 1,
        //     "socketStatus": 0,
        //     "teamId": null,
        //     "flyerName": null,
        //     "comment": null,
        //     "colour": null,
        //     "state": 1,
        //     "store": 2,
        //     "mode": 2,
        //     "isPush": 0,
        //     "param": null,
        //     "latestData": "{\"appId\":87878,\"data\":{\"mountInfo\":[{\"mountName\":\"MMC_Gimbal_Z40\",\"mountType\":0}],\"openid\":\"43754ce8-a95b-47d4-9293-d1b9d6324971\",\"uavInfo\":{\"airSpeed\":8.93500042,\"altitude\":111.71,\"armd\":1,\"barometerTemp\":52.0,\"battaryRemain\":50.128,\"climbRate\":0.11,\"current\":22.84,\"currentMountType\":[],\"customData\":0,\"dateTime\":\"1710470975915\",\"distanceToHome\":534.0,\"distanceToNext\":311.9245202,\"flightDistance\":610.954355,\"flightMode\":\"航线模式\",\"flightSortie\":\"FLY-1655949410-00370022333051173138343000000000\",\"flightState\":1,\"flightTime\":\"136\",\"groundSpeed\":8.856046677,\"height\":99.808,\"imuTemp\":50.84365845,\"isLocation\":\"0\",\"latitude\":\"31.1954157\",\"longitude\":\"118.5356519\",\"pitch\":-6.401669505,\"platformType\":0,\"push_status\":0,\"roll\":-5.114229219,\"satCount\":20.0,\"taskId\":\"53280\",\"time\":1710470975,\"uid\":24,\"unmannedId\":1,\"voltage\":50.128,\"yaw\":281.4067891},\"videoInfo\":[{\"vUrl\":\"rtmp://120.79.35.124:8686/live/river\",\"videoType\":\"rtmp\"},{\"vUrl\":\"http://120.79.35.124:8686/live/river.m3u8\",\"videoType\":\"hls\"},{\"vUrl\":\"webrtc://120.79.35.124:8686/live/traffic.m3u8\",\"videoType\":\"flv\"}]},\"deliveryTag\":0,\"deviceHardId\":\"MMC301R22208068\",\"deviceName\":\"UATAIR-20550\",\"deviceType\":\"1\",\"flightLineDistance\":0.0,\"minDistance\":0.0,\"systemCode\":\"UATAIR\"}",
        //     "udpStatus": 1,
        //     "tcp": null,
        //     "udp": null,
        //     "source_id": null,
        //     "addtime": null,
        //     "editime": null,
        //     "imgUrl": null,
        //     "url": "",
        //     "forceLandingPoint": {
        //         "default": {
        //             "latitude": 31.194922,
        //             "longitude": 118.538854
        //         },
        //         "backup": {
        //             "latitude": 31.175444,
        //             "longitude": 118.62321
        //         }
        //     },
        //     "stationType": 0,
        //     "outTime": null,
        //     "inTime": null,
        //     "cardStatus": null,
        //     "deviceipId": null,
        //     "deviceipOrgName": null,
        //     "ipOrganizationId": null,
        //     "taskId": 53751,
        //     "taskName": null,
        //     "flightCourseJson": null,
        //     "longitude": null,
        //     "latitude": null,
        //     "abnormalContent": null,
        //     "createdBy": null,
        //     "updatedBy": null,
        //     "createdName": null,
        //     "firmId": null,
        //     "firmName": null,
        //     "reportBy": 0,
        //     "goodsName": "TD550",
        //     "userList": null,
        //     "flightState": 1,
        //     "isPxfour": null,
        //     "pid": null,
        //     "orgCode": null,
        //     "gbbh": null,
        //     "GBBH": null,
        //     "_location": false,
        //     "_video": false,
        //     "_airway": false,
        //     "_control": false,
        //     "org_id": 1
        // }, '全国 ')
        // }, 5000);
        
        // 获取无人机列表数据
        this.uav_list();
        this.mqtt_init();
        // 初始化数据接收 ws
        this.uav_init_ws();
        // 初始化数据状态 ws
        this.uav_init_ws_status();
        // 获取航线规划标签
        this.getlable();
        Bus.$on('createTaskBack2List', () => {
            // 如果任务库或者航线库面板还没关闭，则不能点击
            if (this.taskLibraryPanelShow || this.airLineLibraryPanelShow) {
                return;
            }
            // 显示无人机列表面板，隐藏创建任务面板
            this.vuaListHeader = true;
            this.collapseFlagfn();
            this.controlMenuFlag = false;
        });
        // 创建任务面板上点击任务库显示任务库面板
        Bus.$on('taskLibraryShow', (v) => {
            // console.log(v, 'vbvb');
            this.taskLibraryPanelShow = v;
            this.airLineLibraryPanelShow = false;
        });
        // 创建任务面板上点击航线库显示航线库面板，隐藏任务库面板
        Bus.$on('airLineLibraryShow', (v) => {
            this.taskLibraryPanelShow = false;
            this.airLineLibraryPanelShow = v;
        });
        // 任务库面板的显示与隐藏
        Bus.$on('taskLibraryCompShowFlag', (v) => {
            this.taskLibraryPanelShow = v;
        });
        // 手动规划航线组件面板显示控制
        Bus.$on('manualPanelShow', (v) => {
            // 重置航线设置表单
            this.$nextTick(() => {
                this.$refs['creatAirlineForm'].resetFields();
                this.createAirlineDialogForm.name = dayjs(new Date()).format('YYYYMMDDHHmmss');
            });
            // this.manualPanelShow = v;
            // 显示航线设置对话框
            this.newLineDialogVisible = v;
        });
        // 一级面板的显示与隐藏，二级面板要联动
        Bus.$on('secondLevelShow', (v) => {
            /**
             * 二级面板有：
             * 1 任务库面板
             * 2 航线库面板
             * 3 手动规划航线面板
             * **/
            // 收缩任务库
            if (this.taskLibraryPanelShow) {
                this.taskLibraryShrinkFlag = !v;
            }
            // 收缩航线库
            if (this.airLineLibraryPanelShow) {
                this.airLineLibraryShrinkFlag = !v;
            }
            // 手动规划航线面板
            if (this.manualPanelShow) {
                this.manualPlanShrinkFlag = !v;
            }
        });
        // 二级面板任务库向右移动，一级面板，也要展开
        Bus.$on('task-library-move-right', (v) => {
            // 任务库向右移动
            this.taskLibraryShrinkFlag = false;
            // 展开一级面板
            Bus.$emit('create-task-panel-show', true);
        });
        // 二级面板航线库向右移动，以及面板，也要展开
        Bus.$on('airline-library-move-right', (v) => {
            // 任务库向右移动
            this.airLineLibraryShrinkFlag = false;
            // 展开一级面板
            Bus.$emit('create-task-panel-show', true);
        });
        // 二级面板手动规划航线向右移动，一级面板，也要展开
        Bus.$on('airline-library-move-right', (v) => {
            // 任务库向右移动
            this.manualPlanShrinkFlag = false;
            // 展开一级面板
            Bus.$emit('create-task-panel-show', true);
        });

        Bus.$on('clearRealTimeAirLineFromMap', (v) => {
            this.uav.online[v.deviceHardId].positions = [];
            let a = window.viewer.entities.getById(`observe_polylineUav_${v.deviceHardId}`);
            a.polyline.positions = [];
        });
    },
    beforeDestroy() {
        this.uav.control.device = null;
        this.uav.control.device = null;
        this.uav.control.data = null;
        this.uav.control.battery = null;
        this.uav.control.mount = null;
        this.uav.control.mounts = [];
        this.uav.control.mounts = [];
        this.$store.commit('mapmanage/SET_MAP_IS_STATUSl', {
            flag: false,
            type: 'wrj'
        });
        this.uav.videos = [];
        if (this.mqtt.client) {
            this.mqtt.client.end();
            this.mqtt.client = null;
        }
    },
    methods: {
        ...methods,
        async getDeviceInfoByTaskId() {
            let taskId = this.$route.query.taskId
            let res = await API.FCKERNEL.getDeviceInfoByTaskId({
                taskId
            });
            this.uav.control.device = res
            // this.uav.control.device.deviceHardId = "MMC301R22208068"
            // this.uav.control.device.reportStatus = 1
            this.uav_fn_click(4, res, '全国')
            Bus.$emit('uavOnLineFlagFn', res.reportStatus)
        },
        async addMessage() {
            let res = await API.FCKERNEL.exceptionAdd(this.message);
        },
        showLine(val) {
            this.showCenter = val;
        },
        auto() {
            //定点-->航线
            //摇杆手动-->自动
            this.$refs.ControlMenu.changeAuto();
        },
        getimgUrl(val) {
            this.imgshow = true;
            this.imgshowurl = val;
        },
        async qzjg() {
            let res = await API.DEVICE.forceTakeOver({
                deviceHardId: this.uav.NoticeData.deviceHardId
            });
            if (res.code == 200) {
                this.$message.success(res.msg);
                this.uav.NoticeFlag = false;
            }
        },
        fly_take_off() {
            // 等待航线上传成功在执行
            this.$refs.TaskListRef.upload_complete();
            // Bus.$emit("take_off");
        },
        // 无人机列表面板显示或者关闭
        collapseFlagfn() {
            this.collapseFlag = !this.collapseFlag;
            // if (this.collapseFlag == true) {
            // 修改飞控 无人机 左边'任务库'的位置
            // Bus.$emit("ydh", false);
            // } else {
            // Bus.$emit("ydh", true);
            // }
        },
        Lsdom(item) {
            this.$emit('Lsdom', item);
        },
        clearIdFn() {
            if (this.$refs.TaskListRef) {
                this.$refs.TaskListRef.close();
                try {
                    let deviceHardId = this.uav.control.device.deviceHardId;
                    this.uav_hide_airway(this.uav.control.device);
                    this.uav.online[deviceHardId].positions = [this.uav.online[deviceHardId].position];
                } catch (error) { }
            }
        },
        videoItemFn(index) {
            this.videoItem = index;
            this.$store.commit('mapmanage/SET_MAP_IS_VIDEOITEM', index);
        },
        LoggerFn() {
            this.loggerFlag = !this.loggerFlag;
            // this.collapseFlag = false;
        },
        videoChange() {
            this.videoFlag = !this.videoFlag;
        },
        showTakeOver() {
            this.uav.TakeOverFlag = !this.uav.TakeOverFlag;
            if (this.uav.TakeOverFlag) {
                this.collapseFlag = true;
            }
        },
        // 关闭航线创建列表
        AirwayQuit() {
            this.isAirwayEdit = false;
            // 运行监测关闭
            this.powerFlag = true;
            this.Videoflag = true;
            this.ControlFlag = true;
            this.collapseFlag = false;
        },
        taskTypeFn(item) {
            this.taskType = item;
        },
        // 创建航线
        CraeteRoute() {
            this.isAirwayEdit = true;
            // // 运行监测关闭
            this.powerFlag = false;
            this.ControlFlag = false;
            this.collapseFlag = true;
        },
        createTaskClick() {
            this.CreateTaskFlag = !this.CreateTaskFlag;
            this.controlMenuFlag = false;
        },
        async checkUseOperateFn(device) {
            // 查看是否有控制权限
            let res = await API.FCKERNEL.checkUseOperate({
                deviceHardId: device
            });
            if (res.code == 201) {
                this.$message.warning(res.msg);
                return false;
            } else {
                return true;
            }
        },
        /**
         * 无人机搜索
         */
        onUavSearch() {
            console.log(this.uavSearchContent, '无人机列表查询');
            this.uav_list({
                search: this.uavSearchContent
            });
        },
        //点击任务按钮
        onChangeTask(open) {
            if (open === undefined) {
                this.controlMenuFlag = !this.controlMenuFlag;
            } else {
                this.controlMenuFlag = open;
            }
        },
        /** 挂载、健康管理、图库工具栏点击事件
         *@Description:
         *@Date: 2023-10-17 11:31:07
         *@Params1:
         *@Return1:
         */
        gzPanelClose(v) {
            // console.log(v, 'vvvvvvvvvvvvv');
            this.br3ClickedFlag = v;
        },
        /**
         *@Description: 调用创建常态任务、临时紧急任务面板
         *@Date: 2023-10-18 09:57:11
         *@Params1:
         *@Return1:
         */
        createTaskComp(v) {
            // console.log('父组件', v);
            this.createTaskCompTitleFlag = parseInt(v);
            // 关闭任务库
            this.taskLibraryPanelShow = false;
            // 打开创建任务组件
            this.createTaskCompPanelShow = true;
        },
        /**
         *@Description: 关闭创建常态、临时紧急任务面板，显示任务库
         *@Date: 2023-10-18 10:32:30
         *@Params1:
         *@Return1:
         */
        createTaskCompMainShow() {
            // 打开任务库
            this.taskLibraryPanelShow = true;
            // 关闭创建任务组件
            this.createTaskCompPanelShow = false;
            // 通知任务库面板刷新数据
            Bus.$emit('refresh');
        },
        /**
         *@Description: 航线库面板显示与否
         *@Date: 2023-10-18 15:05:58
         *@Params1:
         *@Return1:
         */
        airLineLibraryPanelHandleShow(v) {
            this.airLineLibraryPanelShow = v;
        },
        /**
         *@Description: 创建航线的时候，选择机巢或者无人机
         *@Date: 2023-10-19 10:33:16
         *@Params1:
         *@Return1:
         */
        handle_actionWayChange(v) {
            console.log(v, '选择执行的方式');
            // 如果是无人机，隐藏选择机巢，如果是机巢，则显示选择机巢
            if (v == 'uav') {
                this.nestShowFlag = false;
            } else {
                this.nestShowFlag = true;
            }
        },
        /**
         *@Description: 显示创建航线面板，航线设置对话框提交数据
         *@Date: 2023-10-19 10:34:38
         *@Params1:
         *@Return1:
         */
        createLineDialogSubmit() {
            this.$refs['creatAirlineForm'].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    // 创建航线弹窗隐藏
                    this.newLineDialogVisible = false;
                    // console.log(this.createAirlineDialogForm, '创建航线面板数据');
                    this.createAirlineDialogFormCopy = _.cloneDeep(this.createAirlineDialogForm);
                    // 显示创建航线面板
                    this.manualPanelShow = true;
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        // 取消航线设置
        createLineDialogCancel() {
            // alert("x");
            // this.$refs['creatAirlineForm'].resetFields();
            this.newLineDialogVisible = false;
        },
        /**
         *@Description:获取航线标签，航线规划的时候使用
         *@Date: 2023-10-19 10:46:30
         *@Params1:
         *@Return1:
         */
        // 获取航线标签
        async getlable() {
            this.lablistL = await API.AIRWAY.labelList();
        },
        /**
         *@Description: 关闭航线规划面板
         *@Date: 2023-10-19 10:51:45
         *@Params1:
         *@Return1:
         */
        closeCreatePanel() {
            // 隐藏创建航线面板
            this.manualPanelShow = false;
        },
        /**
         *@Description: 飞行监控里面航线规划选择弹出框
         *@Date: 2023-10-19 10:55:19
         *@Params1:
         *@Return1:
         */
        editCreateAriLinePanelClick(v) {
            // console.log(v, 'f-u');
            this.$nextTick(() => {
                this.createAirlineDialogForm = _.cloneDeep(v.panelForm);
            });

            this.newLineDialogVisible = true;
        },
        /** 当关闭创建任务面板时，打开无人机列表面板
         *@Description:
         *@Author: name
         *@Date: 2023-11-22 10:57:28
         *@Params1:
         *@Return1:
         */
        taskListOpenUvaListPanel(v) {
            // 先检查任务库或者航线库是否关闭，如果没有关闭，则不能关闭创建任务面板
            if (this.taskLibraryPanelShow || this.airLineLibraryPanelShow) {
                this.$message({
                    type: 'warning',
                    message: '请先关闭任务库或者航线库面板'
                });
                return;
            }
            // console.log(v, '当关闭创建任务面板时，打开无人机列表面板');
            //关闭创建任务面板
            this.controlMenuFlag = !v;
            // 打开无人机列表面板
            this.collapseFlag = !v;
        },
        /** 点击无人机列表数据
         *@Description:
         *@Author: name
         *@Date: 2023-12-18 15:36:13
         *@Params1:
         *@Return1:
         */
        uav_fn_click(cate, device, orgName) {
            console.log(' ********************************** 点击无人机列表数据 ********************************** ');
            console.log(cate, 'cate');
            console.log(device, 'device');
            console.log(orgName, 'orgName');
            this.orgName = orgName;
            this.uav_fn(cate, device, orgName);
        },
        /** 在创建任务列表点击接管无人机按钮
         *@Description:
         *@Author: name
         *@Date: 2024-01-10 11:25:26
         *@Params1:
         *@Return1:
         */
        takeoverFromTaskList(v) {
            this.uav_fn(v.cate, v.device, v.orgName);
        },
        // 设置全局高度
        flyHeightClickHandle(v, index) {
            // console.log('v', v);
            let c = parseFloat(this.createAirlineDialogForm.baseHeight) + parseFloat(v);
            if (c < 1 || c > 500) {
                this.$message({
                    type: 'warning',
                    message: '飞行高度不在[1-500]范围内，无法设置'
                });
            } else {
                this.createAirlineDialogForm.baseHeight = c;
            }
        },
        //全局高度输入框
        flyHeightInputHandle(v) {
            let c = parseFloat(v);
            if (c < 2 || c > 1500) {
                this.$message({
                    type: 'warning',
                    message: '飞行高度不在[1-500]范围内，无法设置'
                });
                if (v > 500) {
                    this.createAirlineDialogForm.baseHeight = 500;
                }
                if (v < 1) {
                    this.createAirlineDialogForm.baseHeight = 1;
                }
            } else {
                this.createAirlineDialogForm.baseHeight = v;
            }
            // console.log(v, 'vvvv');
        },
        //设置全局速度
        flySpeedClickHandle(v) {
            let a = parseInt(this.createAirlineDialogForm.baseSpeed) + parseInt(v);
            if (a < 1 || a > 20) {
                this.$message({
                    type: 'warning',
                    message: '飞行速度不在[1-20]范围内，无法设置'
                });
            } else {
                this.createAirlineDialogForm.baseSpeed = a;
            }
        },
        //设置全局速度输入框
        flySpeedInputHandle(v) {
            let a = parseInt(v);
            if (a < 1 || a > 20) {
                this.$message({
                    type: 'warning',
                    message: '飞行速度不在[1-20]范围内，无法设置'
                });
                if (a < 1) {
                    this.createAirlineDialogForm.baseSpeed = 1;
                }
                if (a > 20) {
                    this.createAirlineDialogForm.baseSpeed = 20;
                }
            } else {
                this.createAirlineDialogForm.baseSpeed = a;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.collapse {
    transform: translateX(-100%);
    transition: 0.3s;
}

.page-observe-fckernel-uav {
    // height: 100%;
    // width: 100%;
    // position: relative;
    // border: 1px solid yellow;

    .videoBox {
        /* width: 382px; */
        // border: 1px solid #D8D8D8;
        position: fixed;
        top: 8%;
        right: 100px;
        // top: -95px;
        // padding-bottom: 10px;
        // left: 496px;
        width: 587px;
        height: 347px;
        height: auto;
        // border: 1px solid yellow;
    }

    // .control-menu {
    //   position: absolute;
    //   border: 1px solid red;
    //   z-index: 10000;
    // }
}

.nsetLeftBox {
    // display: none;
    position: absolute;
    width: 460px;
    left: 80px;
    top: 64px;
    // height: 86vh;
    z-index: 99;
    transition: 0.3s;
    background: rgba(9, 32, 87, 0.7);
    border-radius: 10px 10px 0px 0px;

    // ********************************************
    width: 410px;
    height: 1018px;
    background: #0a293a;

    // border: 1px solid #70daf9;
    .nsetLeftBox_btn {
        position: absolute;
        cursor: pointer;
        right: -22px;
        top: 50%;
        margin-top: -90px;
        z-index: 99;
    }
}

.acitve {
    color: #70daf9;
}

.uav-search {
    width: 410px;
    height: 64px;
    background: #133e55;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 7px;
    // margin-top: 14px;
    background-color: #133e55;

    .uav-search__input ::v-deep {
        // width: 317px;
        height: 40px;
        // box-shadow: 0px 2px 4px 0px rgba(28, 94, 124, 0.5),
        //   inset 0px 0px 3px 0px #00b6ff;
        border-radius: 4px;
        border: 0;

        .el-input__icon {
            line-height: 32px;
        }

        input {
            height: 40px;
            // background: #02173d;
            background-color: #0a293a;
            color: #43deff;
            border: 0px solid #315ec7;

            &::placeholder {
                font-size: 12px;
                color: #7bb5d5;
            }
        }
    }

    .uav-search__btn {
        // padding: 7px 25px 6px 26px;
        // background: rgba(38, 71, 238, 0.71);
        // background: url("~@/assets/images/observe/twobg.png");
        border-radius: 4px;
        border: 1px solid;
        font-size: 14px;
        color: #fff;
        width: 42px;
        height: 40px;
        background: #4e87ac;
        border-radius: 4px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
    }
}

.uav-list-header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 460px;
    height: 33px;
    background: linear-gradient(180deg, #9198ff 0%, rgba(45, 81, 153, 0.45) 40%, #05091a 100%);
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .uav-list-header__text {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg, #f7b67d 38%, #f9eacb 58%, #f5d2a6 79%, #f59743 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .uav-list-header__icon {
        width: 26px;
        margin-left: 9px;
    }
}

.imgshowurl {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba($color: #000000, $alpha: 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;

    img {
        width: 1000px;
    }

    .close {
        color: #fff;
        margin-left: 1000px;
        margin-bottom: 16px;
        // position: absolute;
        // right: 10px;
        // top: 10px;
    }
}

.warn {
    position: absolute;
    left: calc(50% - 220px);
    top: 10%;
    padding: 10px 14px;
    // height: 36px;
    background: rgba(249, 42, 42, 0.6);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
        word-break: break-all;
        width: 400px;
        color: #fff;
    }

    .close {
        cursor: pointer;
        color: #fff;
    }
}

.uav-list-header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 460px;
    height: 33px;
    background: linear-gradient(180deg, #9198ff 0%, rgba(45, 81, 153, 0.45) 40%, #05091a 100%);
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .uav-list-header__text {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg, #f7b67d 38%, #f9eacb 58%, #f5d2a6 79%, #f59743 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .uav-list-header__icon {
        width: 26px;
        margin-left: 9px;
    }
}

.imgshowurl {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba($color: #000000, $alpha: 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;

    img {
        width: 1000px;
    }

    .close {
        color: #fff;
        margin-left: 1000px;
        margin-bottom: 16px;
        // position: absolute;
        // right: 10px;
        // top: 10px;
    }
}

.warn {
    position: absolute;
    left: calc(50% - 220px);
    top: 10%;
    padding: 10px 14px;
    // height: 36px;
    background: rgba(249, 42, 42, 0.6);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
        word-break: break-all;
        width: 400px;
        color: #fff;
    }

    .close {
        cursor: pointer;
        color: #fff;
    }
}

.kedu {
    bottom: 0;
    left: 100px;
    width: 300px;
    height: 500px;
    position: fixed;
    background: #000;
    overflow: hidden;

    .line {
        // transform: translateY(-250px);
        width: 100%;
        height: 100%;
    }

    .center {
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        width: 50px;
        height: 50px;
    }
}

.side-bar {
    position: absolute;
    top: 64px;
    left: 0;
    z-index: 100;
}

.nsetLeftBox {
    .uav-list-header-new {
        width: 410px;
        height: 54px;
        background: #224d68;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        box-sizing: border-box;

        .title {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
        }
    }
}

.collapse-container {
    position: absolute;
    width: 460px;
    left: 80px;
    top: 64px;
    z-index: 110;
    // border: 1px solid yellow;
    width: 410px;
    height: 54px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    // justify-content: space-between;

    .img {
        width: 21px;
        height: 24px;
        cursor: pointer;
    }

    .img1 {
        float: left;
    }

    .img2 {
        float: right;
        margin-left: 375px;
    }

    .img-con {
        width: 52px;
        height: 54px;
        background: #224d68;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
}

.task-library {
    position: absolute;
    top: 64px;
    left: 490px;
    z-index: 121;
    width: 410px;
    // height: 1015px;
    height: calc(100% - 64px);
    background: #0a293a;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.task-library-shrink {
    left: 80px !important;
}

.airline-library,
.manual-panel {
    position: absolute;
    top: 64px;
    left: 490px;
    // z-index: 121;
    z-index: 221 !important;
    width: 410px;
    // height: 1015px;
    height: calc(100% - 64px);
    background: #0a293a;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.uav-create-task {
    position: absolute;
    top: 64px;
    left: 490px;
    z-index: 121;
    width: 410px;
    // height: 1015px;
    height: calc(100% - 64px);
    background: #0a293a;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow-y: auto;
}
</style>

<style lang="scss">
.el-notification {
    background-color: rgba(204, 0, 1, 0.7);
    border: 0;
}

.el-notification__title {
    color: #ffe610;
    font-size: 15px;
}

.el-notification__content {
    color: #fff;
    font-size: 13px;
}

.el-notification__closeBtn {
    color: #fff;
}

.create-new-line-dialog {
    background-color: #0a293a;

    .el-dialog__header {
        height: 40px;
        background: #133e55;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .el-dialog__title {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
        }

        .el-dialog__headerbtn {
            display: none;
        }
    }

    .el-dialog__body {
        padding: 10px 25px 10px;
    }

    .el-dialog__footer {
        text-align: end;

        .el-button {
            width: 114px;
            height: 40px;
            background: #133d54;
            border-radius: 4px;
            border: 0;
            color: #fff;
        }

        .el-button--primary {
            width: 114px;
            height: 40px;
            background: #007aff;
            border-radius: 4px;
        }
    }

    .el-form-item__content {
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        width: 100%;
        margin-top: -8px;

        .el-input__inner {
            color: #fff;
        }
    }

    .el-form-item__label {
        color: #fff;
        padding: 0;
        margin-top: 6px;
    }

    .el-form-item {
        margin-bottom: 0px;
    }

    .create-way-item {
        .el-form-item__content {
            border: 0;
            display: flex;
        }

        .create-way {
            width: 100px;
            height: 40px;
            background: #133e55;
            border-radius: 4px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .create-way-manual {
            margin-left: 10px;
        }

        .create-way-selected {
            border: 1px solid #007aff;
        }
    }

    .average-height-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 4px;

        .btn {
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            // border: 1px solid yellow;
            height: 32px;
            background-color: #1f526e;
            cursor: pointer;
            border-radius: 4px;
        }

        .el-input {
            width: 75px;
            // height: 30px;
        }

        .el-input__inner {
            background-color: #043957;
        }
    }

    .body-base-speed {
        display: flex;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-around;

        .img {
            height: 24px;
            width: 24px;
            cursor: pointer;
        }

        .slider {
            width: 45%;
        }

        .speed-input {
            width: 70px;
        }

        .el-input__inner {
            background-color: #043957;
        }

        .unit {
            color: #fff;
        }

        .el-slider__runway {
            height: 3px;
        }

        .el-slider__bar {
            height: 3px;
        }

        .el-slider__button {
            height: 12px;
            width: 12px;
            background-color: #2b72ea;
            border: 2px solid #fff;
        }
    }
}
</style>
<style lang="scss">
@import '@/styles/wuhuCommon.scss';
</style>