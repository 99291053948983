<!-- 飞控新版鹰视新版 -->
<template>
    <div class="nest_controlBox nest_controlBox_new">
        <!-- <PositionBall class="pa ding" :uav-data="uavData" />
    <ymfz :channelData="data"> </ymfz>
    <img class="img2" src="@/assets/img/ball2.png" />
    <img class="img3" src="@/assets/img/ball2.png" />
    <div class="w900 h78 nest_control">
      <InfoDJ class="w550 ml80 h78" v-if="device.goodsName == 'M300'" :uav-data="uavData" :wsShow="wsShow"></InfoDJ>
      <Info :offline='offline' class="w550 ml80 h78" v-else :uav-data="uavData" :wsShow="wsShow"></Info>
      <div class="xian" v-if="!device.goodsName == 'M300'"></div>
      <battery :uav-battery="uavBattery" :device="device"></battery>
    </div>

    <div class="bizhang">
      <obstacle :uav-data="uavData"></obstacle>
        </div>-->

        <!-- --------------------------------------------------------------------------- -->
        <!-- 
        //TODO: 飞行监控面板
        -->
        <div class="left">
            <div class="top-line" v-if="false">
                <div class="item item1">
                    <div class="icon"></div>
                </div>
                <div class="item item2">
                    <div class="icon"></div>
                </div>
                <div class="item item3"></div>
            </div>
            <div class="line line1" v-if="false">
                <div class="left">
                    <el-progress class="circle-left-minute" type="circle" color="#3CE171" :percentage="leftMinutes"
                        :stroke-width="13"></el-progress>
                    <span class="left-minute">预计还要6分钟</span>
                </div>
                <div class="right">
                    <span>照片:35/60</span>
                </div>
            </div>
            <div class="line line2">
                <div class="lonlat">
                    <el-tooltip effect="dark" content="视频背景模式" placement="top">
                        <span v-if="!videoBgFlag" class="iconfont icon-basefangda3" @click="vidoeBgFn"></span>
                        <span v-else class="iconfont icon-basesuoxiao6" @click="vidoeBgFn"></span>
                    </el-tooltip>
                    <span class="lon">经度：{{ longitudeInit() }}</span>
                    <span class="lat">纬度：{{ latitudeInit() }}</span>
                </div>

                <div class="item item1">
                    <div class="card card1">
                        <span class="name">飞行高度(m)</span>
                        <span class="value">{{ heightInit() }}</span>
                    </div>
                    <div class="card card2" v-if="false">
                        <span class="name">起点距离(m)</span>
                        <span class="value">{{ distanceToHomeInit() }}</span>
                    </div>
                </div>
                <el-divider class="divider" direction="vertical"></el-divider>

                <div class="item item2">
                    <div class="card card1">
                        <span class="name">海拔高度(m)</span>
                        <span class="value">{{ altitudeInit() }}</span>
                    </div>
                    <div class="card card2">
                        <span class="name">飞行时间(min)</span>
                        <span class="value">{{ flightTimeInit() }}</span>
                    </div>
                </div>
                <el-divider class="divider" direction="vertical"></el-divider>

                <div class="item item3">
                    <div class="card card1">
                        <span class="name">飞行里程(m)</span>
                        <span class="value">{{ flightDistanceInit() }}</span>
                    </div>
                    <div class="card card2">
                        <span class="name">飞行速度(m/s)</span>
                        <span class="value">{{ groundSpeedInit() }}</span>
                    </div>
                </div>
                <el-divider class="divider" direction="vertical"></el-divider>

                <div class="item item4">
                    <div class="card card1" v-if="false">
                        <div class="img"></div>
                        <div class="txt">
                            <span class="name">电量</span>
                            <!-- <span class="value">{{ uavBattery && parseFloat(parseFloat(uavBattery.chargeRemaining)).toFixed(0) }}%</span> -->
                            <span class="value">{{ batteryInit() }}</span>
                            <!-- <span>{{ uavBattery.chargeRemaining }}</span> -->
                        </div>
                    </div>
                    <div class="card card2">
                        <span class="name">爬升率(m/s)</span>
                        <span class="value">{{ climbRateInit() }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="middle">
            <div class="item item1">
                <div class="left-value">
                    <div class="vv v1">{{ pitchInit() }}</div>
                    <div class="vv v2">
                        <div class="t1">
                            <!-- 空气速度 -->
                            <span class="t1-name">SPD</span>
                            <span class="t1-unit">m/S</span>
                        </div>
                        <div class="t2">{{ groundSpeedInit() }}</div>
                        <div class="t3">
                            <div class="flag"></div>
                        </div>
                    </div>
                    <div class="vv v3">
                        <span class="name">WS</span>
                        <span class="value"></span>
                        <img src="@/assets/img/i52.png" alt class="img" />
                    </div>
                </div>
                <div class="right-legth">
                    <div class="bar">
                        <div class="bar-divide"></div>
                        <div class="bar-divide"></div>
                        <div class="bar-divide"></div>
                        <div class="bar-divide"></div>
                        <div class="bar-divide"></div>
                    </div>
                </div>
            </div>
            <div class="item item2">
                <span class="yaw-text">{{ initYaw() }}</span>
                <img class="trangle" src="@/assets/img/i21.png" alt />
                <!-- <img src="@/assets/img/i53.png" alt="" class="img" :style="{ rotate: imgYaw + 'deg' }"> -->
                <img src="@/assets/img/i53.png" alt class="img" :style="{ rotate: initYaw() + 'deg' }" />
                <img src="@/assets/img/i54.png" alt class="img-center" />
                <img src="@/assets/img/i55.png" alt class="img-line" />
                <div class="h">H</div>
            </div>
            <div class="item item3">
                <div class="right-legth">
                    <div class="bar">
                        <div class="bar-divide bar-divide1"></div>
                        <div class="bar-divide bar-divide2"></div>
                        <div class="bar-divide bar-divide3">
                            <div class="up"></div>
                            <div class="down"></div>
                        </div>
                    </div>
                    <div class="b-t">
                        <div class="h">H</div>
                        <div class="v">{{ distanceToHomeInit() }}m</div>
                    </div>
                </div>
                <div class="left-value">
                    <div class="vv v1">
                        <span class="v">{{ climbRateInit() }}</span>
                        <span class="u">VS</span>
                    </div>
                    <div class="vv v2">
                        <div class="t2">{{ heightInit() }}</div>
                        <div class="t1">
                            <span class="t1-name">ALT</span>
                            <span class="t1-unit">m</span>
                        </div>
                    </div>
                    <div class="vv v3">
                        <span class="value">{{ altitudeInit() }}</span>
                        <span class="name">ALS</span>
                        <!-- <img src="@/assets/img/i52.png" alt="" class="img"> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="line line1">
                <div class="item item1">
                    <!-- 一键起飞 -->
                    <div class="img-con" @click="yjqf">
                        <img class="img" src="@/assets/img/i59.png" alt />
                    </div>
                    <span class="name">一键起飞</span>
                </div>
                <div class="item item2">
                    <div class="img-con" @click="yjfh">
                        <img class="img" src="@/assets/img/i60.png" alt />
                    </div>
                    <span class="name">一键返航</span>
                </div>
                <div class="item item3">
                    <div class="img-con" @click="aqjl">
                        <img class="img" src="@/assets/img/i56.png" alt />
                    </div>
                    <span class="name">安全降落</span>
                </div>
                <div class="item item4">
                    <div class="img-con" @click="ztfx">
                        <img class="img" src="@/assets/img/i61.png" alt v-if="ztfxFlag" />
                        <img class="img" src="@/assets/img/i61-1.png" alt v-else />
                    </div>
                    <span class="name" v-if="ztfxFlag">暂停飞行</span>
                    <span class="name" v-else>继续飞行</span>
                </div>
                <div class="item item5">
                    <div class="img-con" @click="zdfx">
                        <img class="img" src="@/assets/img/i57.png" alt />
                    </div>
                    <span class="name">指点飞行</span>
                </div>
                <div class="item item6">
                    <div class="img-con" @click="fxms">
                        <img class="img" src="@/assets/img/i58.png" alt />
                    </div>
                    <span class="name">飞行模式</span>
                </div>
            </div>
            <div class="line line2">
                <div class="item item-jjpj">
                    <div class="img-con" @click="jjpj">
                        <img class="img" src="@/assets/img/jjpj.png" alt />
                    </div>
                    <span class="name">一键备降</span>
                </div>

                <div class="card card1">
                    <div class="item item1">
                        <span class="name">Q</span>
                        <img class="img" src="@/assets/img/i63.png" alt />
                    </div>
                    <div class="item item2">
                        <span class="name">F</span>
                        <img class="img" src="@/assets/img/i63.png" alt />
                    </div>
                    <div class="item item3">
                        <span class="name">C</span>
                        <img class="img" src="@/assets/img/i64.png" alt />
                    </div>
                    <div class="item item4">
                        <span class="name">z</span>
                        <img class="img" src="@/assets/img/i64.png" alt />
                    </div>
                </div>
                <div class="card card2">
                    <div class="item item1">
                        <span class="name">A</span>
                        <img class="img" src="@/assets/img/i62.png" alt />
                    </div>
                    <div class="item item2">
                        <span class="name">D</span>
                        <img class="img" src="@/assets/img/i62.png" alt />
                    </div>
                    <div class="item item3">
                        <span class="name">W</span>
                        <img class="img" src="@/assets/img/i62.png" alt />
                    </div>
                    <div class="item item4">
                        <span class="name">z</span>
                        <img class="img" src="@/assets/img/i62.png" alt />
                    </div>
                </div>
            </div>
        </div>

        <!-- ---------------------------------- 指点飞行开始---------------------------------------------- -->
        <!-- 指点飞行弹框显示 -->
        <transition name="el-fade-in">
            <div class="zdfx-panel" v-show="zdfxShowFlag">
                <div class="header" id="dragTitle" style="cursor: move;" v-interact>指点飞行</div>
                <div class="body">
                    <div class="line line1">
                        <div class="item item1">
                            <div class="title">飞行高度(1-500m)</div>
                            <div class="fly fly-height">
                                <div class="btn btn1" @click="flyHeightClick(-50)">-50</div>
                                <div class="btn btn2" @click="flyHeightClick(-10)">-10</div>
                                <div class="btn btn3" @click="flyHeightClick(-1)">-1</div>
                                <el-input class="height-input" v-model="form.flyHeight" size="small"></el-input>
                                <div class="btn btn4" @click="flyHeightClick(1)">+1</div>
                                <div class="btn btn5" @click="flyHeightClick(10)">+10</div>
                                <div class="btn btn6" @click="flyHeightClick(50)">+50</div>
                            </div>
                        </div>
                        <div class="item item2">
                            <div class="title">飞行速度(1-20m/s)</div>
                            <div class="fly fly-speed">
                                <div class="plus" @click="flySpeedClick(-1)"></div>
                                <el-slider class="fly-speed-slider" v-model="form.flySpeed" :min="2"
                                    :max="20"></el-slider>
                                <div class="add" @click="flySpeedClick(1)"></div>
                                <el-input class="height-input" v-model="form.flySpeed" size="small"></el-input>
                                <div class="unit">m/s</div>
                            </div>
                        </div>
                    </div>
                    <div class="line line2">
                        <div class="title">航点位置</div>
                        <div class="body">
                            <div class="item item-left">
                                <el-input v-model="form.lon" class="lonlat-input" @change="zdfxLonChange" size="small">
                                    <template slot="prepend">经度</template>
                                </el-input>
                                <el-input v-model="form.lat" class="lonlat-input" @change="zdfxLatChange" size="small">
                                    <template slot="prepend">纬度</template>
                                </el-input>
                            </div>
                            <div class="item item-right">
                                <img src="@/assets/img/control.png" alt />
                                <img class="img-arrow img-arrow1" src="@/assets/img/i-w.png"
                                    @click="zdfxLonClick(0.000005)" alt />
                                <img class="img-arrow img-arrow2" src="@/assets/img/i-w.png"
                                    @click="zdfxLonClick(-0.000005)" alt />
                                <img class="img-arrow img-arrow3" src="@/assets/img/i-w.png"
                                    @click="zdfxLatClick(0.000005)" alt />
                                <img class="img-arrow img-arrow4" src="@/assets/img/i-w.png"
                                    @click="zdfxLatClick(-0.000005)" alt />
                            </div>
                        </div>
                    </div>
                    <div class="line line3">
                        <div class="btn cancel" @click="cancel">取消</div>
                        <div class="btn submit" @click="submit">飞行确认</div>
                    </div>
                </div>
            </div>
        </transition>

        <!-- 指点飞行 飞行确认 -->
        <transition name="el-fade-in">
            <div class="zdfx-fly-submit" v-show="zdfxFlySubmitFlag">
                <div class="header" id="dragTitle" v-interact></div>
                <div class="body">
                    <img class="img" src="@/assets/img/i-q.png" alt />
                    <span class="text">位置获取成功，请确认是否进行指点模式飞行？</span>
                </div>
                <div class="btn-con">
                    <div class="btn btn-cancel" style="display: flex; align-items: center; justify-content: center;"
                        @click="reCancel">取消</div>
                    <div class="btn btn-ok" style="display: flex; align-items: center; justify-content: center;"
                        @click="reSubmit">确定</div>
                </div>
            </div>
        </transition>

        <!-- 指点飞行 绘制的点提示框 -->
        <div class="zdfx-point-tips" v-show="zdfxPointTipsFlag">
            <span class="item">高度：{{ form.flyHeight }}m</span>
            <span class="item">速度：{{ form.flySpeed }}m/s</span>
            <span class="item">经度：{{ form.lon }}</span>
            <span class="item">纬度：{{ form.lat }}</span>
            <img @click="zdfxEdit" class="img" src="@/assets/img/i-edit.png" alt />
        </div>
        <!-- ---------------------------------- 指点飞行结束---------------------------------------------- -->

        <!-- ---------------------------------- 紧急迫降弹窗开始---------------------------------------------- -->
        <transition name="el-fade-in">
            <div class="zdfx-panel jjpj-panel" v-show="jjpjShowFlag">
                <div class="header" id="dragTitle" style="cursor: move;" v-interact>一键备降</div>
                <div class="body">
                    <div class="line line1">
                        <div class="item item1">
                            <div class="title">飞行高度(1-500m)</div>
                            <div class="fly fly-height">
                                <div class="btn btn1" @click="flyHeightClick(-50)">-50</div>
                                <div class="btn btn2" @click="flyHeightClick(-10)">-10</div>
                                <div class="btn btn3" @click="flyHeightClick(-1)">-1</div>
                                <el-input class="height-input" v-model="jjpjForm.flyHeight" size="small"></el-input>
                                <div class="btn btn4" @click="flyHeightClick(1)">+1</div>
                                <div class="btn btn5" @click="flyHeightClick(10)">+10</div>
                                <div class="btn btn6" @click="flyHeightClick(50)">+50</div>
                            </div>
                        </div>
                        <div class="item item2">
                            <div class="title">飞行速度(1-20m/s)</div>
                            <div class="fly fly-speed">
                                <div class="plus" @click="flySpeedClick(-1)"></div>
                                <el-slider class="fly-speed-slider" v-model="jjpjForm.flySpeed" :min="2"
                                    :max="20"></el-slider>
                                <div class="add" @click="flySpeedClick(1)"></div>
                                <el-input class="height-input" v-model="jjpjForm.flySpeed" size="small"></el-input>
                                <div class="unit">m/s</div>
                            </div>
                        </div>
                    </div>
                    <div class="line line2">
                        <!-- <div class="title">航点位置</div> -->
                        <div class="body">
                            <div class="item item-left">
                                <el-tabs v-model="jjpjActiveName" @tab-click="jjpjHandleClick">
                                    <el-tab-pane label="默认备降点" name="default">
                                        <el-input v-model="jjpjForm.defaultLon" class="lonlat-input"
                                            @change="zdfxLonChange" size="small" disabled>
                                            <template slot="prepend">经度</template>
                                        </el-input>
                                        <el-input v-model="jjpjForm.defaultLat" class="lonlat-input"
                                            @change="zdfxLatChange" size="small" disabled>
                                            <template slot="prepend">纬度</template>
                                        </el-input>
                                    </el-tab-pane>
                                    <el-tab-pane label="备用备降点" name="standby">
                                        <el-input v-model="jjpjForm.standByLon" class="lonlat-input"
                                            @change="zdfxLonChange" size="small" disabled>
                                            <template slot="prepend">经度</template>
                                        </el-input>
                                        <el-input v-model="jjpjForm.standByLat" class="lonlat-input"
                                            @change="zdfxLatChange" size="small" disabled>
                                            <template slot="prepend">纬度</template>
                                        </el-input>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                            <div class="item item-right" v-if="false">
                                <img src="@/assets/img/control.png" alt />
                                <img class="img-arrow img-arrow1" src="@/assets/img/i-w.png"
                                    @click="zdfxLonClick(0.000005)" alt />
                                <img class="img-arrow img-arrow2" src="@/assets/img/i-w.png"
                                    @click="zdfxLonClick(-0.000005)" alt />
                                <img class="img-arrow img-arrow3" src="@/assets/img/i-w.png"
                                    @click="zdfxLatClick(0.000005)" alt />
                                <img class="img-arrow img-arrow4" src="@/assets/img/i-w.png"
                                    @click="zdfxLatClick(-0.000005)" alt />
                            </div>
                        </div>
                    </div>
                    <div class="line line3">
                        <div class="btn cancel" @click="jjpjShowFlag = false">取消</div>
                        <div class="btn submit" @click="jjpjHandleSubmit">飞行确认</div>
                    </div>
                </div>
            </div>
        </transition>
        <!-- ---------------------------------- 紧急迫降弹窗结束---------------------------------------------- -->
    </div>
</template>

<script>
import PositionBall from './components/ball/index';
import Info from './components/info/index';
import InfoDJ from './components/infoDJ/index';
import battery from './components/battery/index';
import ymfz from './components/ymfz';
import obstacle from './components/obstacle';
import { mapGetters } from 'vuex';
import Bus from '@/assets/ligature.js';
let Point = null;
import API from '@/api';
import _ from 'lodash';
import SymbolIcon from '@/components/observe/fckernel/common/symbol-icon';
let handler = null;

export default {
    data() {
        return {
            leftMinutes: 50,
            uavDataNew: {
                climbRate: 0
            },
            zdfxShowFlag: false,
            // 指点飞行表单
            form: {
                flyHeight: 100,
                flySpeed: 5,
                lon: '',
                lat: ''
            },
            // 紧急迫降表单
            jjpjForm: {
                flyHeight: 100,
                flySpeed: 5,
                defaultLon: '118',
                defaultLat: '19',
                standByLon: '105',
                standByLat: '25'
            },
            zdfxFlySubmitFlag: false,
            zdfxPointTipsFlag: false,
            zdfxFlag: false, //指点飞行点击可以在图上绘制，一次下发成功之后，就结束在图上绘制，想要绘制，重新点击 指点飞行
            imgYaw: 0,
            videoBgFlag: false, //视频背景模式
            ztfxFlag: true, //暂停飞行标志
            username: null,
            jjpjShowFlag: false, //紧急迫降弹窗显隐标志
            jjpjActiveName: 'default' //紧急迫降选择默认迫降点还是备用迫降点
        };
    },
    props: {
        uavData: {
            type: Object,
            default: () => ({})
        },
        uavBattery: {
            type: Object,
            default: () => ({})
        },
        data: {
            type: Object,
            default: () => ({})
        },
        device: {
            type: Object,
            default: () => ({})
        },
        wsShow: {
            type: Boolean,
            default: () => {
                false;
            }
        },
        offline: {
            type: Boolean,
            default: () => {
                true;
            }
        }
    },
    // components: { PositionBall, Info, InfoDJ, battery, ymfz, obstacle },
    components: {},
    mounted() {
        // console.log(this.device,"infodevice");
        // Bus.$on('uav-status', (val) => {
        //   console.log(val, 'valllllllllllll');
        //   if (!Object.hasOwn(val, 'climbRate')) {
        //     val.climbRate = 0;
        //   }
        //   this.uavDataNew = val;
        //   this.imgYaw = this.uavDataNew.yaw ? parseFloat(this.uavDataNew.yaw).toFixed(2) : 0;
        // });
        // console.log(this.uavBattery, 'uavBatteryuavBatteryuavBattery');
        // Bus.$on('uav-battery-bus', (val) => {
        //   console.log(val, 'valllllllllllll');
        // });
    },
    computed: {
        ...mapGetters(['user_info']),
        props_obj() {
            let { data } = this;
        },
        xxclib() {
            return this.uavDataNew?.climbRate || '1098';
        },
        uavB() {
            console.log(this.uavBattery, 'uavBatteryuavBatteryuavBattery');
        }
    },
    watch: {
        uavData: (newValue, oldValue) => {
            // console.log(newValue, 'newValuenewValuenewValuenewValuenewValue');
            // debugger
        },
        uavBattery: (newValue, oldValue) => { }
    },
    created() {
        this.username = this.user_info.username;
    },
    methods: {
        /** todo 查看是否有控制权限
         *@Description:查看是否有控制权限
         *@Date: 2023-10-19 15:39:40
         *@Params1:
         *@Return1:
         */
        async checkUseOperateFn(device) {
            // 查看是否有控制权限
            console.log(device, '飞行监控，飞行指令调用前检查用户权限参数');
            let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
            console.log(res, '飞行监控，飞行指令调用前检查用户权限返回值');
            if (res.code == 201) {
                this.$message.warning(res.msg);
                return false;
            } else {
                return true;
            }
        },
        /** todo 一键起飞
         *@Description:一键起飞
         *@Date: 2023-10-19 15:35:00
         *@Params1:
         *@Return1:
         */
        async yjqf() {
            // TODO: 指定用户操作权限 - 一键起飞
            if (this.device.deviceName.indexOf('#') != -1) {
                if (this.username !== 'xwsyb00' && this.username !== 'xwsyb01' && this.username !== 'xwsyb02') {
                    return;
                }
            }
            await this.$confirm('无人机即将起飞、悬停，请确认无人机周边飞行安全', '安全确认', { center: true });
            console.log(this.device, '一键起飞下发指令 一键起飞，飞行设备');
            let flag = await this.checkUseOperateFn(this.device.deviceHardId);
            if (!flag) return false;
            if (this.wsShow) {
                // let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
                // 任务ID找不到
                // 地面站链路
                this.$emit('fun', {
                    type: 200,
                    systemCode: 'uatair',
                    state: 1,
                    username: this.username,
                    data: {
                        // cmdFunction: 2113, 下发任务改为 2114
                        cmdFunction: 2111
                        // cmdValue: this.flightId || this.flightIdv2, //航线id
                        // cmdValue: waypointList.flightLineId, //航线id
                        // taskId: this.flyTypeId + "",  ???
                    },
                    deviceHardId: this.device.deviceHardId
                });
            } else {
                // 飞控链路 原地起飞
                let height = JSON.parse(this.device?.latestData?.data?.uavInfo) || 30;
                this.$emit('fn', {
                    type: 515,
                    data: parseFloat(height)
                });
            }
            this.$message({ type: 'success', message: '一键起飞指令下发成功' });
        },
        /** todo 一键返航
         *Description:一键返航
         *@Date: 2023-10-19 15:45:26
         *@Params1:
         *@Return1:
         */
        async yjfh() {
            // TODO: 指定用户操作权限 - 一键返航
            if (this.device.deviceName.indexOf('#') != -1) {
                if (this.username !== 'xwsyb00' && this.username !== 'xwsyb01' && this.username !== 'xwsyb02') {
                    return;
                }
            }
            let flag = await this.checkUseOperateFn(this.device.deviceHardId);
            if (!flag) return;
            await this.$confirm('请确认是否进行一键返航操作？', '安全确认', {
                center: true
            });
            console.log(this.device, '一键返航下发指令 一键返航，飞行设备');
            if (!this.wsShow) {
                Bus.$emit('uavFan', 'AUTO_RTL');
                // 飞控链路
                this.$emit('fn', {
                    type: 517,
                    data: null
                });
            } else {
                // 地面站链路
                this.$emit('fun', {
                    type: 200,
                    systemCode: 'mmc',
                    state: 1,
                    username: this.username,
                    data: {
                        cmdFunction: 2112,
                        taskId: this.device.taskId
                    },
                    deviceHardId: this.device.deviceHardId
                });
            }
            this.$message({
                type: 'success',
                message: '一键返航指令下发成功'
            });
        },
        /** todo 安全降落
         *@Description:安全降落
         *@Date: 2023-10-19 15:55:13
         *@Params1:
         *@Return1:
         */
        async aqjl() {
            // TODO: 指定用户操作权限 - 安全降落
            if (this.device.deviceName.indexOf('#') != -1) {
                if (this.username !== 'xwsyb00' && this.username !== 'xwsyb01' && this.username !== 'xwsyb02') {
                    return;
                }
            }
            let flag = await this.checkUseOperateFn(this.device.deviceHardId);
            if (!flag) return;
            this.$confirm('无人机即将原地降落，请确认无人机下方是否安全！', '安全降落', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                customClass: 'uav_controlPane',
                showClose: false
            })
                .then(async () => {
                    this.remind();
                })
                .catch((error) => {
                    console.log(error, 'error');
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
        },
        /** todo 安全降落确认
         *@Description: 安全降落确认
         *@Date: 2023-10-19 16:08:33
         *@Params1:
         *@Return1:
         */
        remind() {
            if (!this.wsShow) {
                // 飞控链路
                this.$emit('fn', { type: 516, data: null });
            } else {
                // 地面站链路
                this.$emit('fun', {
                    type: 200,
                    systemCode: 'mmc',
                    state: 1,
                    username: this.username,
                    data: {
                        cmdFunction: 2116
                    },
                    deviceHardId: this.device.deviceHardId
                });
            }
            this.$message.success('安全降落指令下发成功');
        },
        /** todo 暂停/继续飞行
         *@Description:暂停飞行
         *@Date: 2023-10-19 16:15:50
         *@Params1:
         *@Return1:
         */
        async ztfx() {
            // TODO: 指定用户操作权限 - 继续/暂停飞行
            if (this.device.deviceName.indexOf('#') != -1) {
                if (this.username !== 'xwsyb00' && this.username !== 'xwsyb01' && this.username !== 'xwsyb02') {
                    return;
                }
            }
            let flag = await this.checkUseOperateFn(this.device.deviceHardId);
            if (!flag) return false;
            this.ztfxFlag = !this.ztfxFlag;
            if (!this.ztfxFlag) {
                // 暂停飞行
                if (!this.wsShow) {
                    this.$emit('fn', { type: 523, data: null });
                } else {
                    //暂停飞行
                    this.$emit('fun', {
                        type: 200,
                        systemCode: 'mmc',
                        state: 1,
                        username: this.username,
                        data: {
                            cmdFunction: 2117
                        },
                        deviceHardId: this.device.deviceHardId
                    });
                }
                this.$message.success('暂停飞行指令下发成功');
            } else {
                // 继续飞行
                if (!this.wsShow) {
                    this.$emit('fn', { type: 524, data: null });
                } else {
                    this.$emit('fun', {
                        type: 200,
                        systemCode: 'mmc',
                        state: 1,
                        username: this.username,
                        data: {
                            cmdFunction: 2118
                        },
                        deviceHardId: this.device.deviceHardId
                    });
                }
                this.$message.success('继续飞行指令下发成功');
            }
        },
        /** todo: 点击指点飞行按钮，指点飞行
         *@Description: 点击指点飞行按钮，指点飞行
         *@Date: 2023-10-19 16:19:06
         *@Params1:
         *@Return1:
         */
        async zdfx() {
            if (this.jjpjShowFlag) {
                this.$message('请先退出紧急迫降程序');
                return;
            }
            // TODO: 指定用户操作权限 - 指点飞行
            if (this.device.deviceName.indexOf('#') != -1) {
                if (this.username !== 'xwsyb00' && this.username !== 'xwsyb01' && this.username !== 'xwsyb02') {
                    return;
                }
            }
            // 0 清除之前绘制的点
            if (Point) {
                window.viewer.entities.remove(Point);
                this.zdfxPointTipsFlag = false;
            }
            this.zdfxFlag = true;
            // 1 消息提示
            this.$message('请点击地图获取目标位置');
            // 2 地图绘制
            let viewer = window.viewer;
            this.zdfcFlag = false;
            let position = null;
            handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
            handler.setInputAction((movement) => {
                if (!this.zdfxFlag) {
                    return;
                }
                let tipsDomC3 = viewer.scene.globe.pick(viewer.camera.getPickRay(movement.position), viewer.scene);
                let earthPosition = viewer.camera.pickEllipsoid(movement.position, viewer.scene.globe.ellipsoid);
                let cartographic = Cesium.Cartographic.fromCartesian(earthPosition, viewer.scene.globe.ellipsoid, new Cesium.Cartographic());
                let lng = Cesium.Math.toDegrees(cartographic.longitude);
                let lat = Cesium.Math.toDegrees(cartographic.latitude);
                let height = parseInt(cartographic.height);
                this.form.lon = parseFloat(lng).toFixed(6);
                this.form.lat = parseFloat(lat).toFixed(6);
                if (Point) {
                    window.viewer.entities.remove(Point);
                }
                position = Cesium.Cartesian3.fromDegrees(lng, lat, 20);
                Point = this.addModeelPoint(window.viewer, position, '目标点');
                let tipsDom = document.querySelector('.zdfx-point-tips');
                let x = movement.position.x;
                let y = movement.position.y;
                tipsDom.style.left = `${x - 120}px`;
                tipsDom.style.top = `${y - 70}px`;
                this.zdfxPointTipsFlag = true;
                viewer.scene.postRender.addEventListener((percentage) => {
                    if (this.zdfxPointTipsFlag) {
                        //转换到屏幕坐标
                        let winpos = viewer.scene.cartesianToCanvasCoordinates(tipsDomC3);
                        if (winpos) {
                            // console.log(this.zdfxPointTipsFlag, 'that.zdfxPointTipsFlag');
                            let x = (winpos.x - 120).toFixed(0);
                            let y = (winpos.y - 70).toFixed(0);
                            tipsDom.style.left = `${x - 50}px`;
                            tipsDom.style.top = `${y}px`;
                        }
                    }
                });
                // 3 弹出指点飞行框
                this.zdfxShowFlag = true;
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
            return null;
        },
        /** todo 指点飞行相关
         *@Description: 指点飞行相关
         *@Date: 2023-10-19 16:39:52
         *@Params1:
         *@Return1:
         */
        addModeelPoint(viewer, position, name) {
            let p = viewer.entities.add({
                position,
                point: {
                    show: false,
                    pixelSize: 12,
                    color: Cesium.Color.fromCssColorString('#ee0000'),
                    outlineColor: Cesium.Color.fromCssColorString('#fff'),
                    outlineWidth: 1
                    // show: true,
                },
                label: {
                    text: `高度：${this.form.flyHeight} \r\n
                    速度：${this.form.flySpeed} \r\n
                    经度：${this.form.lon} \r\n
                    未读：${this.form.lat} \r\n`,
                    show: false,
                    scale: 0.5,
                    horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                    verticalOrigin: Cesium.VerticalOrigin.CENTER,
                    disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    showBackground: true,
                    outlineWidth: 0,
                    pixelOffset: new Cesium.Cartesian2(0, -30)
                },
                billboard: {
                    image: 'image/ipoint.png',
                    show: true,
                    // sacle: 1000.01,
                    // color: Cesium.Color.YELLOWGREEN,
                    width: 33,
                    height: 40
                }
            });
            // 绘制之后就销毁地图事件 销毁地图鼠标事件
            handler = handler && handler.destroy();
            return p;
        },
        // 指点飞行面板点击飞行高度按钮
        flyHeightClick(v) {
            if (this.zdfxShowFlag) {
                let temp = this.form.flyHeight + v;
                if (temp < 1 || temp > 500) {
                    this.$message({
                        type: 'warning',
                        message: '飞行高度区间在1-500m'
                    });
                    return;
                }
                this.form.flyHeight = temp;
            } else {
                let temp = this.jjpjForm.flyHeight + v;
                if (temp < 1 || temp > 500) {
                    this.$message({
                        type: 'warning',
                        message: '飞行高度区间在-500m'
                    });
                    return;
                }
                this.jjpjForm.flyHeight = temp;
            }
        },
        // 指点飞行面板点击飞行速度
        flySpeedClick(v) {
            if (this.zdfxShowFlag) {
                let temp = this.form.flySpeed + v;
                if (temp > 20 || temp < 1) {
                    this.$message({
                        type: 'warning',
                        message: '飞行速度区间在1-20m'
                    });
                    return;
                }
                this.form.flySpeed += v;
            } else {
                let temp = this.jjpjForm.flySpeed + v;
                if (temp > 20 || temp < 1) {
                    this.$message({
                        type: 'warning',
                        message: '飞行速度区间在1-20m'
                    });
                    return;
                }
                this.jjpjForm.flySpeed += v;
            }
        },
        // 指点飞行面板点击取消
        cancel() {
            this.zdfxShowFlag = false;
            this.form.flyHeight = 100;
            this.form.flySpeed = 5;
            this.form.lon = '';
            this.form.lat = '';
            handler = handler && handler.destroy();
            this.zdfxPointTipsFlag = false;
            if (Point) {
                window.viewer.entities.remove(Point);
            }
        },
        // 指点飞行，飞行确认
        submit() {
            console.log(this.form);
            this.zdfxShowFlag = false;
            this.zdfxFlySubmitFlag = true;
        },
        // 指点飞行 取消飞行确认
        reCancel() {
            // 销毁地图鼠标事件
            handler = handler && handler.destroy();
            this.zdfxFlySubmitFlag = false;
            this.zdfxShowFlag = true;
            // if (Point) {
            //   window.viewer.entities.remove(Point);
            // }
        },
        // 指点飞行，飞行确认，点击确认，下发指令
        reSubmit() {
            this.zdfxFlySubmitFlag = false;
            this.zdfxPointTipsFlag = true;
            // 显示信息提示框
            this.zdfxPointTipsFlag = true;
            // 销毁地图鼠标事件
            handler = handler && handler.destroy();
            if (!this.wsShow) {
                // 飞控链路
                this.$emit('fn', {
                    data: {
                        latitude: this.form.lat,
                        longitude: this.form.lon,
                        speed: this.form.flySpeed,
                        altitude: this.form.flyHeight
                        // heading: 2219, //现在(2023年12月26日 15:04:18)没有航线选项
                    },
                    type: 518
                });
            } else {
                // 地面站链路
                this.$emit('fun', {
                    type: 200,
                    systemCode: 'uatair',
                    state: 1,
                    username: this.username,
                    data: {
                        lat: this.form.lat,
                        lng: this.form.lon,
                        speed: this.form.flySpeed,
                        height: this.form.flyHeight,
                        // cmdFunction: 2219
                        cmdFunction: 2119 //指令修改为 2119
                    },
                    deviceHardId: this.device.deviceHardId
                });
            }
            this.$message({
                message: '指点飞行指令下发成功',
                type: 'success',
                duration: 3000,
                customClass: 'zdfx-success-message'
            });
            // TODO 指点飞行删除之前的航线
            Bus.$emit('clearRealTimeAirLineFromMap', this.device);
            // 禁止在图上绘制点，必须再点击 指点飞行按钮
            this.zdfxFlag = false;
        },
        // 指点飞行编辑
        zdfxEdit() {
            this.zdfxShowFlag = true;
            // this.zdfxPointTipsFlag = false;
        },
        // 指点飞行面板经度变化
        zdfxLonChange(v) {
            let lon = v;
            let lat = this.form.lat;
            let c3 = new Cesium.Cartesian3.fromDegrees(parseFloat(lon), parseFloat(lat), 20);
            // console.log(v, c3);
            Point.position = c3;
        },
        // 指点飞行面板纬度变化
        zdfxLatChange(v) {
            let lon = this.form.lon;
            let lat = v;
            let c3 = new Cesium.Cartesian3.fromDegrees(parseFloat(lon), parseFloat(lat), 20);
            Point.position = c3;
        },
        // 指点飞行航点位置点击右侧控制面板, 上下箭头，经度加减
        zdfxLonClick(v) {
            this.form.lon = parseFloat(parseFloat(v) + parseFloat(this.form.lon)).toFixed(6);
            let c3 = new Cesium.Cartesian3.fromDegrees(parseFloat(this.form.lon), parseFloat(this.form.lat), 20);
            Point.position = c3;
        },
        // 指点飞行航点位置点击右侧控制面板, 左右箭头，纬度加减
        zdfxLatClick(v) {
            this.form.lat = parseFloat(parseFloat(v) + parseFloat(this.form.lat)).toFixed(6);
            let c3 = new Cesium.Cartesian3.fromDegrees(parseFloat(this.form.lon), parseFloat(this.form.lat), 20);
            Point.position = c3;
        },
        // 关闭操作区域事件
        myClose() {
            this.zdfxPointTipsFlag = false;
            // 销毁地图鼠标事件
            handler = handler && handler.destroy();
        },
        /** todo 行模式 ---> 应该是航线模式
         *@Description:飞行模式 ---> 应该是航线模式
         *@Date: 2023-10-19 16:19:35
         *@Params1:
         *@Return1:
         */
        async fxms() {
            // TODO: 指定用户操作权限 - 飞行模式
            if (this.device.deviceName.indexOf('#') != -1) {
                if (this.username !== 'xwsyb00' && this.username !== 'xwsyb01' && this.username !== 'xwsyb02') {
                    return;
                }
            }
            await this.$confirm('无人机将切换为航线模式', '安全确认', {
                center: true
            });
            let flag = await this.checkUseOperateFn(this.device.deviceHardId);
            if (!flag) return false;
            if (this.wsShow) {
                // let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
                // 任务ID找不到
                this.$emit('fun', {
                    type: 200,
                    systemCode: 'uatair',
                    state: 1,
                    username: this.username,
                    data: {
                        // cmdFunction: 2113, 下发任务改为 2114
                        // cmdFunction: 2115,
                        cmdFunction: parseInt(2115),
                        cmdValue: 1
                        // cmdValue: this.flightId || this.flightIdv2, //航线id
                        // cmdValue: waypointList.flightLineId, //航线id
                        // taskId: this.flyTypeId + "",  ???
                    },
                    deviceHardId: this.device.deviceHardId
                });
            } else {
                alert('飞控链路');
                // 选择航线模式 AUTO_MISSION
                this.$emit('fn', { type: 513, data: 'AUTO_MISSION' });
            }
        },
        /**
         *@Description: 视频背景模式
         *@Author: name
         *@Date: 2023-11-15 11:28:22
         *@Params1:
         *@Return1:
         */
        vidoeBgFn() {
            this.videoBgFlag = !this.videoBgFlag;
            if (!this.videoBgFlag) {
                document.querySelectorAll('.uav_videoBox')[0].style.width = '587px';
                document.querySelectorAll('.uav_videoBox')[0].style.top = '8%';
                document.querySelectorAll('.uav_videoBox')[0].style.right = '100px';
                document.querySelectorAll('.cpt_video')[0].style.width = '587px';
                document.querySelectorAll('#layer-container')[0].style.width = '100%';
                document.querySelectorAll('#layer-container')[0].style.height = '100%';
                document.querySelectorAll('#layer-container')[0].style.bottom = '0';
                document.querySelectorAll('#layer-container')[0].style.zIndex = 'unset';
            } else {
                document.querySelectorAll('.uav_videoBox')[0].style.width = '100%';
                document.querySelectorAll('.uav_videoBox')[0].style.zIndex = '200';
                document.querySelectorAll('.uav_videoBox')[0].style.top = '7%';
                document.querySelectorAll('.uav_videoBox')[0].style.right = '0';
                document.querySelectorAll('.cpt_video')[0].style.width = '100%';
                document.querySelectorAll('#layer-container')[0].style.width = '360px';
                document.querySelectorAll('#layer-container')[0].style.height = '260px';
                document.querySelectorAll('#layer-container')[0].style.bottom = '40px';
                document.querySelectorAll('#layer-container')[0].style.left = '10px';
                document.querySelectorAll('#layer-container')[0].style.zIndex = 230;
            }
        },
        batteryInit() {
            let a = this.uavBattery?.battaryRemain ? this.uavBattery.battaryRemain : 0;
            return a + '%';
        },
        pitchInit() {
            let a = this.uavData?.pitch ? parseFloat(this.uavData.pitch).toFixed(1) : 0;
            if (isNaN(a)) {
                return 0 + '°';
            } else {
                return a + '°';
            }
        },
        initYaw() {
            let a = this.uavData?.yaw ? parseFloat(this.uavData.yaw).toFixed(1) : 0;
            if (isNaN(a)) {
                return 0 + '°';
            } else {
                return a + '°';
            }
        },
        /* todo 经纬度初始化
         *@Description:
         *@Author: name
         *@Date: 2023-12-26 09:50:42
         *@Params1:
         *@Return1:
         */
        longitudeInit() {
            let a = this.uavData?.longitude ? this.uavData.longitude : 0;
            return isNaN(a) ? 0 : parseFloat(a).toFixed(6);
        },
        latitudeInit() {
            let a = this.uavData?.latitude ? this.uavData.latitude : 0;
            return isNaN(a) ? 0 : parseFloat(a).toFixed(6);
        },
        heightInit() {
            let a = this.uavData?.height ? this.uavData.height.toFixed(2) : 0;
            return isNaN(a) ? 0 : a;
        },
        distanceToHomeInit() {
            let a = this.uavData?.distanceToHome ? this.uavData.distanceToHome.toFixed(2) : 0;
            return isNaN(a) ? 0 : a;
        },
        altitudeInit() {
            let a = this.uavData?.altitude ? this.uavData.altitude.toFixed(2) : 0;
            return isNaN(a) ? 0 : a;
        },
        flightTimeInit() {
            let a = this.uavData?.flightTime ? (this.uavData.flightTime / 60).toFixed(2) : 0;
            return isNaN(a) ? 0 : a;
        },
        flightDistanceInit() {
            let a = this.uavData?.flightDistance ? this.uavData.flightDistance.toFixed(2) : 0;
            return isNaN(a) ? 0 : a;
        },
        groundSpeedInit() {
            let a = this.uavData?.groundSpeed ? this.uavData.groundSpeed.toFixed(2) : 0;
            return isNaN(a) ? 0 : Math.abs(a);
        },
        climbRateInit() {
            let a = this.uavData?.climbRate ? this.uavData.climbRate.toFixed(2) : 0;
            return isNaN(a) ? 0 : a;
        },
        /** 紧急迫降弹窗
         *@Description:
         *@Author: name
         *@Date: 2024-01-10 11:45:47
         *@Params1:
         *@Return1:
         */
        jjpj() {
            if (this.zdfxShowFlag) {
                this.$message('请先退出指点飞行程序');
                return;
            }
            // 1 重置表单
            this.jjpjForm.flyHeight = 100;
            this.jjpjForm.flySpeed = 5;
            // 2 查询迫降点
            this.jjpjForm.defaultLon = this.device.forceLandingPoint ? this.device.forceLandingPoint.default.longitude : '暂无';
            this.jjpjForm.defaultLat = this.device.forceLandingPoint ? this.device.forceLandingPoint.default.latitude : '暂无';
            this.jjpjForm.standByLon = this.device.forceLandingPoint ? this.device.forceLandingPoint.backup.longitude : '暂无';
            this.jjpjForm.standByLat = this.device.forceLandingPoint ? this.device.forceLandingPoint.backup.latitude : '暂无';
            // 3 弹窗
            this.jjpjShowFlag = true;
        },
        /** 紧急迫降选择默认迫降点还是备用迫降点
         *@Description:
         *@Author: name
         *@Date: 2024-01-10 14:09:23
         *@Params1:
         *@Return1:
         */
        jjpjHandleClick(v) {
            console.log(v, 'v');
        },
        /** 紧急迫降提交
         *@Description:
         *@Author: name
         *@Date: 2024-01-10 15:13:20
         *@Params1:
         *@Return1:
         */
        jjpjHandleSubmit() {
            let lon = 0,
                lat = 0;
            console.log('this.jjpjActiveName', this.jjpjActiveName);

            if (this.jjpjActiveName == 'default') {
                lon = this.jjpjForm.defaultLon;
                lat = this.jjpjForm.defaultLat;
            } else {
                lon = this.jjpjForm.standByLon;
                lat = this.jjpjForm.standByLat;
            }
            if (lon == '暂无' || lat == '暂无') {
                this.$message.warning('备降点无效');
                return;
            }
            //
            this.jjpjShowFlag = false;
            // 下发指点飞行命令---等于是紧急迫降
            if (!this.wsShow) {
                // 飞控链路
                this.$emit('fn', {
                    data: {
                        latitude: lat,
                        longitude: lon,
                        speed: this.jjpjForm.flySpeed,
                        altitude: this.jjpjForm.flyHeight
                        // heading: 2219, //现在(2023年12月26日 15:04:18)没有航线选项
                    },
                    type: 518
                });
            } else {
                // 地面站链路
                this.$emit('fun', {
                    type: 200,
                    systemCode: 'uatair',
                    state: 1,
                    username: this.username,
                    data: {
                        lat: lat,
                        lng: lon,
                        speed: this.jjpjForm.flySpeed,
                        height: this.jjpjForm.flyHeight,
                        cmdFunction: 2219
                    },
                    deviceHardId: this.device.deviceHardId
                });
            }
            // // TODO 一键备降删除之前的航线
            Bus.$emit('clearRealTimeAirLineFromMap', this.device);
            this.$message({
                message: '紧急迫降指令下发成功',
                type: 'success',
                duration: 3000,
                customClass: 'zdfx-success-message'
            });
        }
    }
};
</script>

<style scoped lang="scss">
.nest_controlBox {
    pointer-events: fill;
    // width: 860px;
    // height: 200px;
    position: absolute;
    display: flex;
    justify-content: start;
    justify-content: space-evenly;
    align-items: center;
    left: calc(50% - 450px);
    bottom: 10px;
    z-index: 1;
    // pointer-events: none;
    padding: 10px 10px 10px 0;

    // width: 1172px;
    width: 65%;
    // height: 233px;
    height: 180px;
    background: rgba(10, 41, 58, 0.9);
    border-radius: 8px;
    box-sizing: border-box;
    // border: 1px solid yellow;

    .left {
        width: 34%;
        height: 180px;
        border-radius: 8px;
        // border: 1px solid #747776;
        position: relative;
        padding: 0 10px 0 10px;
        box-sizing: border-box;

        .top-line {
            height: 3px;
            width: 60%;
            background-color: transparent;
            position: absolute;
            top: -1.5px;
            left: 4px;
            display: flex;
            align-items: center;
            justify-content: center;

            .item {
                height: 100%;
                display: flex;
                justify-content: flex-end;
            }

            .item1 {
                width: 35%;
                background-color: #e02020;

                .icon {
                    width: 13px;
                    height: 13px;
                    background-color: #fff;
                    margin-top: -5px;
                    border-radius: 50%;
                    background-image: url('~@/assets/img/i49.png');
                    background-repeat: no-repeat;
                    background-size: 70% 80%;
                    background-position: center;
                }
            }

            .item2 {
                width: 50%;
                background-color: #f7b500;

                .icon {
                    width: 13px;
                    height: 13px;
                    background-color: #fff;
                    margin-top: -5px;
                    border-radius: 50%;
                    background-image: url('~@/assets/img/i50.png');
                    background-repeat: no-repeat;
                    background-size: 70% 80%;
                    background-position: center;
                }
            }

            .item3 {
                width: 25%;
                background-color: #3ce171;
            }
        }

        .line {
            width: 100%;
            box-sizing: border-box;
            // border: 1px solid #F7B500;
        }

        .line1 {
            height: 35%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                // background-color: #F7B500;
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;

                .circle-left-minute {
                    width: 43px;
                    height: 43px;
                    // width: 20px;
                    // height: 18px;
                    font-size: 18px;
                    font-family: DINCondensed-Bold, DINCondensed;
                    font-weight: bold;
                    color: #ffffff;
                    // line-height: 22px;
                }

                .left-minute {
                    width: 96px;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 20px;
                    margin-left: 10px;
                }
            }

            .right {
                height: 100%;
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                // background-color: #3CE171;
                width: 83px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
                border: 0;
            }
        }

        .line2 {
            // height: 65%;
            height: 100%;
            // border: 1px solid #F7B500;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            box-sizing: border-box;
            padding-top: 10%;
            position: relative;

            .lonlat {
                position: absolute;
                top: 10px;
                left: 0px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;

                .lon,
                .lat {
                    font-size: 14px;
                    color: #00f3fe;
                    font-size: 12px;
                }

                .lat {
                    margin-left: 10px;
                }

                .iconfont {
                    font-size: 14px;
                    margin-right: 8px;
                    color: #00f3fe;
                    cursor: pointer;
                }
            }

            .item {
                width: 24%;
                height: 98%;
                // border: 1px solid yellow;
                box-sizing: border-box;

                .card {
                    width: 100%;
                    height: 45%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    // border: 1px solid yellow;

                    .name {
                        font-size: 12px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #949caa;
                    }

                    .value {
                        font-size: 16px;
                        font-family: DINAlternate-Bold, DINAlternate;
                        font-weight: bold;
                        color: #ffffff;
                        margin-top: 6px;
                    }
                }
            }

            .divider {
                height: 8em;
                margin: 0 1px;
                background-color: #979797;
            }

            .item4 {
                .card1 {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .img {
                        width: 50%;
                        height: 50%;
                        background-image: url('~@/assets/img/i51.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }

    .middle {
        width: 34%;
        height: 180px;
        border-radius: 8px;
        // border: 1px solid #747776;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 20px;

        .item {
            height: 80%;
            // border: 1px solid yellow;
            box-sizing: border-box;
        }

        .item1 {
            width: 32%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .left-value {
                width: 74%;
                height: 65%;
                // border: 1px solid red;
                position: relative;

                .vv {
                    position: absolute;
                }

                .v1 {
                    right: 0px;
                    top: 14%;
                    height: 35%;
                    color: #fff;
                }

                .v2 {
                    right: 0px;
                    top: 30%;
                    // border: 1px solid red;
                    width: 100%;
                    height: 35%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-direction: row;

                    .t1 {
                        width: 30%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        .t1-name {
                            font-size: 13px;
                            font-family: Helvetica-Bold, Helvetica;
                            font-weight: bold;
                            color: #12ffd8;
                        }

                        .t1-unit {
                            margin-top: 3px;
                            font-size: 13px;
                            font-family: Helvetica-Bold, Helvetica;
                            font-weight: bold;
                            color: #12ffd8;
                        }
                    }

                    .t2 {
                        width: 65%;
                        font-size: 25px;
                        font-family: Helvetica;
                        color: #12ffd8;
                        line-height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .t3 {
                        width: 5%;
                        position: relative;

                        .flag {
                            position: absolute;
                            top: -6px;
                            right: -10px;
                            width: 11px;
                            height: 11px;
                            background: #3bdcee;
                            // width: 0;
                            // height: 0;
                            // border-left: 11px solid transparent;
                            // border-right: 11px solid transparent;
                            // border-bottom: 100px solid red;
                        }
                    }
                }

                .v3 {
                    width: 100%;
                    height: 30%;
                    right: 0px;
                    top: 65%;
                    // border: 1px solid red;
                    width: 100%;
                    height: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-direction: row;

                    .name,
                    .value {
                        font-size: 13px;
                        font-family: Helvetica-Bold, Helvetica;
                        font-weight: bold;
                        color: #ffffff;
                    }

                    .img {
                        width: 15px;
                        height: 17px;
                        transform: rotate(45deg);
                    }
                }
            }

            .right-legth {
                width: 26%;
                height: 65%;
                // border: 1px solid rgb(0, 162, 255);
                display: flex;
                align-items: center;
                justify-content: center;

                .bar {
                    width: 12px;
                    height: 100%;
                    background-color: #656b76;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    flex-direction: column;

                    .bar-divide {
                        width: 100%;
                        height: 2px;
                        background-color: #fff;
                    }
                }
            }
        }

        .item2 {
            width: 34%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            // border: 1px solid #000;
            margin-top: 6px;

            .yaw-text {
                position: absolute;
                top: -18px;
                left: 33%;
                color: #ffffff;
                font-size: 13px;
            }

            .trangle {
                position: absolute;
                top: -2px;
                left: 42%;
                color: #ffffff;
                font-size: 13px;
                z-index: 300;
            }

            .img {
                transform: scale(0.8);
                // rotate: 60deg;
            }

            .img-center {
                position: absolute;
                top: 46%;
            }

            .img-line {
                position: absolute;
                top: 26%;
                height: 36px;
                transform: rotate(30deg);
                transform-origin: bottom;
            }

            .h {
                width: 18px;
                height: 18px;
                background: transparent;
                border-radius: 50%;
                position: absolute;
                bottom: 28%;
                right: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: #ffda00;
                border: 2px solid #ffda00;
            }
        }

        .item3 {
            width: 32%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .left-value {
                width: 74%;
                height: 65%;
                // border: 1px solid red;
                position: relative;

                .vv {
                    position: absolute;
                }

                .v1 {
                    right: 0px;
                    top: 14%;
                    height: 35%;
                    color: #fff;
                    // background-color: #3CE171;
                    margin-top: -5px;

                    .v,
                    .u {
                        font-size: 13px;
                        font-family: Helvetica;
                        color: #ffffff;
                    }

                    .u {
                        margin-left: 5px;
                    }
                }

                .v2 {
                    right: 0px;
                    top: 30%;
                    // border: 1px solid red;
                    width: 100%;
                    height: 35%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-direction: row;
                    // background-color: #508b82;

                    .t1 {
                        width: 30%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        .t1-name {
                            font-size: 13px;
                            font-family: Helvetica-Bold, Helvetica;
                            font-weight: bold;
                            color: #12ffd8;
                        }

                        .t1-unit {
                            margin-top: 3px;
                            font-size: 13px;
                            font-family: Helvetica-Bold, Helvetica;
                            font-weight: bold;
                            color: #12ffd8;
                        }
                    }

                    .t2 {
                        width: 65%;
                        font-size: 25px;
                        font-family: Helvetica;
                        color: #12ffd8;
                        line-height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .t3 {
                        width: 5%;
                        position: relative;

                        .flag {
                            position: absolute;
                            top: -6px;
                            right: -10px;
                            width: 11px;
                            height: 11px;
                            background: #3bdcee;
                            // width: 0;
                            // height: 0;
                            // border-left: 11px solid transparent;
                            // border-right: 11px solid transparent;
                            // border-bottom: 100px solid red;
                        }
                    }
                }

                .v3 {
                    width: 100%;
                    height: 30%;
                    right: 0px;
                    top: 65%;
                    // border: 1px solid red;
                    width: 100%;
                    height: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    flex-direction: row;

                    .name,
                    .value {
                        font-size: 13px;
                        font-family: Helvetica-Bold, Helvetica;
                        font-weight: bold;
                        color: #ffffff;
                    }

                    .name {
                        margin-left: 10px;
                    }
                }
            }

            .right-legth {
                width: 26%;
                height: 65%;
                // border: 1px solid rgb(0, 162, 255);
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                justify-content: flex-start;

                .bar {
                    width: 12px;
                    height: 100%;
                    // background-color: #656B76;
                    // border: 1px solid yellow;

                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .bar-divide {
                        width: 100%;
                        // background-color: #fff;
                    }

                    .bar-divide1 {
                        height: calc(50% - 2px);
                        background-color: #656b76;
                    }

                    .bar-divide2 {
                        height: 2px;
                        background-color: #fff;
                        margin: 1px 0 1px 0;
                    }

                    .bar-divide3 {
                        height: calc(50% - 2px);

                        .up {
                            width: 100%;
                            height: 50%;
                            background-color: #dfa226;
                        }

                        .down {
                            width: 100%;
                            height: 50%;
                            background-color: #df3326;
                        }
                    }
                }

                .b-t {
                    position: absolute;
                    top: 100%;
                    height: 40px;
                    width: 100%;
                    // border: 1px solid pink;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    // bottom: 10%;

                    .h {
                        width: 18px;
                        height: 18px;
                        background: transparent;
                        border-radius: 50%;
                        position: absolute;
                        bottom: 35%;
                        right: 15%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 13px;
                        font-family: Helvetica-Bold, Helvetica;
                        font-weight: bold;
                        color: #ffda00;
                        border: 2px solid #ffda00;
                    }

                    .v {
                        background: transparent;
                        position: absolute;
                        bottom: -10%;
                        right: -20%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 13px;
                        font-family: Helvetica-Bold, Helvetica;
                        font-weight: bold;
                        color: #ffda00;
                    }
                }
            }
        }
    }

    .right {
        width: 30%;
        height: 180px;
        border-radius: 8px;
        // border: 1px solid #747776;

        .line {
            width: 100%;
        }

        .line1 {
            height: 40%;
            // border: 1px solid yellow;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .item {
                width: 15%;
                height: 80%;
                cursor: pointer;

                .img-con {
                    width: 44px;
                    height: 44px;
                    background: #3d4959;
                    border: 1px solid #0c0c0c;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;

                    .img {
                        width: 27px;
                        height: 20px;
                    }
                }

                .name {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }
            }
        }

        .line2 {
            height: 60%;
            // border: 1px solid pink;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            .item-jjpj {
                position: absolute;
                top: 10px;
                left: 0;
                width: 15%;
                height: 80%;
                cursor: pointer;

                .img-con {
                    width: 44px;
                    height: 44px;
                    background: #3d4959;
                    border: 1px solid #0c0c0c;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;

                    .img {
                        width: 27px;
                        height: 20px;
                    }
                }

                .name {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }
            }

            .card {
                height: 85%;
                width: 35%;
                border: 1px solid #747776;
                border-radius: 12px;
                position: relative;

                .item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 35px;
                    height: 35px;
                    background: #52657d;
                    border-radius: 4px;
                    border: 1px solid #8195af;
                    cursor: pointer;

                    .name {
                        width: 11px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 20px;
                    }

                    .img {
                        width: 14px;
                        height: 13px;
                    }
                }

                .item1 {
                    .img {
                        transform: scaleX(-1);
                    }

                    position: absolute;
                    top: 28%;
                    left: 2%;
                }

                .item2 {
                    .img {
                        // transform: scaleX(-1);
                    }

                    position: absolute;
                    top: 28%;
                    right: 2%;
                }

                .item3 {
                    .img {
                        // transform: scaleX(-1);
                    }

                    position: absolute;
                    top: 6%;
                    left: 34.5%;
                }

                .item4 {
                    .img {
                        transform: scaleY(-1);
                    }

                    position: absolute;
                    bottom: 6%;
                    left: 34.5%;
                }
            }

            .card2 {
                margin-left: 30px;

                .item1 {
                    .img {
                        transform: rotate(-90deg);
                    }
                }

                .item2 {
                    .img {
                        transform: rotate(90deg);
                    }
                }

                .item3 {
                    .img {
                        transform: rotate(0deg);
                    }
                }

                .item4 {
                    .img {
                        transform: scaleY(-1);
                    }
                }
            }
        }
    }

    .nest_control {
        display: flex;
        align-items: center;
        background: rgba(9, 32, 87, 0.7);
    }

    .img2 {
        position: absolute;
        left: -36px;
        top: calc(35%);
        width: 8px;
        height: 9px;
        z-index: 9999;
        transform: rotate(-75deg);
    }

    .img3 {
        position: absolute;
        left: -40px;
        top: calc(34%);
        width: 8px;
        height: 9px;
        z-index: 9999;
        transform: rotate(115deg);
    }
}

.xian {
    width: 0.3px;
    height: 72px;
    border: 0.5px solid;
    border-image: linear-gradient(180deg, rgba(112, 218, 249, 0), rgba(112, 218, 249, 1), rgba(112, 218, 249, 0)) 2 2;
    margin: 10px 10px 0 0;
}

.bizhang {
    box-sizing: border-box;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: -50px;
    top: 14px;
}

.kong {
    width: 40px;
}

.ding {
    left: -31px;
}

.zdfx-panel {
    position: fixed;
    top: 40vh;
    left: 45vw;
    z-index: 100;
    width: 366px;
    height: 420px;
    background: #0a293a;
    border-radius: 8px;

    .header {
        width: 366px;
        height: 40px;
        background: #133e55;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 10px;
        box-sizing: border-box;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .body {
        height: calc(100% - 40px);
        padding: 10px 15px;
        box-sizing: border-box;

        .line {
            width: 100%;
            border-radius: 6px;
            background-color: #133e55;
        }

        .line1 {
            height: 43%;

            .item {
                width: 100%;
                height: 50%;
                padding: 10px 10px 0 10px;
                box-sizing: border-box;
                // padding

                .title {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    height: 20px;
                    // background-color: #14faff;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .fly {
                    height: calc(100% - 20px);
                    // background-color: #9198ff;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .btn {
                        height: 28px;
                        width: 11.5%;
                        background-color: #1f526e;
                        border-radius: 0;
                        margin-top: 2px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                    }

                    .btn1 {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }

                    .btn6 {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }

                    .height-input {
                        // width: 25%;
                        width: 16%;
                        // height: 28px;
                        background: #0a293a;
                        border-radius: 2px;

                        :deep(.el-input__inner) {
                            background-color: #05091a !important;
                        }
                    }
                }

                .plus,
                .add {
                    width: 22px;
                    height: 22px;
                    background: #1f526e;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    cursor: pointer;
                }

                .plus {
                    margin-right: 12px;
                    background-image: url('~@/assets/img/i-plus.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }

                .add {
                    margin-left: 12px;
                    margin-right: 5px;
                    background-image: url('~@/assets/img/i-add.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }

                .fly-speed-slider {
                    width: 56%;
                }

                .unit {
                    color: #ffffff;
                }
            }
        }

        .line2 {
            height: 33%;
            margin-top: 10px;
            padding: 10px 10px 0 10px;
            box-sizing: border-box;

            .title {
                height: 20px;
                width: 100%;
                color: #fff;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
            }

            .body {
                height: calc(100% - 20px);
                box-sizing: border-box;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 0 5px 0;

                .item {
                    // background-color: #00ffff;
                    height: 100%;
                }

                .item-left {
                    width: 65%;

                    .lonlat-input {
                        margin-top: 5px;
                    }
                }

                .item-right {
                    width: 35%;
                    // background-color: #00ffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    .img-arrow {
                        height: 22px;
                        width: 22px;
                        position: absolute;
                        cursor: pointer;
                    }

                    .img-arrow1 {
                        transform: rotate(-90deg);
                        top: 0;
                        left: 40%;
                    }

                    .img-arrow2 {
                        transform: rotate(90deg);
                        bottom: 1%;
                        left: 40%;
                    }

                    .img-arrow3 {
                        transform: rotate(0deg);
                        bottom: 36%;
                        right: 16%;
                    }

                    .img-arrow4 {
                        transform: rotate(180deg);
                        bottom: 36%;
                        left: 16%;
                    }
                }
            }
        }

        .line3 {
            height: 19%;
            // margin-top: 10px;
            // background-color: #00ffff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: #0a293a;

            .btn {
                color: #fff;
                width: 30%;
                height: 40px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .cancel {
                background: #133e55;
            }

            .submit {
                background: #007aff;
                margin-left: 10px;
            }
        }
    }
}

.jjpj-panel {
    top: 30vh;

    .body {

        .line1,
        .line2 {
            height: 40%;
        }

        .line3 {
            height: 20%;
        }

        .line2 {
            .body {
                .item-left {
                    width: 100%;

                    /deep/ .el-tabs__header {
                        height: auto !important;
                        line-height: unset !important;
                        margin: 0 !important;
                    }

                    /deep/ .el-tabs__active-bar {
                        display: none;
                    }

                    /deep/ .el-tabs__nav-wrap::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.zdfx-fly-submit {
    position: fixed;
    top: 30vh;
    left: 45vw;
    z-index: 100;
    width: 366px;
    height: 180px;
    background: #0a293a;
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .header {
        width: 100%;
        height: 40px;
        background: #133e55;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
    }

    .body {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(100% - 100px);
        // background-color: #00ffff;

        .img {
            margin-right: 5px;
        }

        .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
        }
    }

    .btn-con {
        height: 60px;
        width: 100%;
        // background-color: #f5cda9;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // padding-top: -10px;
        box-sizing: border-box;
        padding-right: 10px;

        // btn-cancel
        .btn {
            width: 35%;
            height: 40px;
            background: #133e55;
            border-radius: 4px;
            color: #fff;
            margin-top: -10px;
        }

        .btn-ok {
            width: 114px;
            height: 40px;
            background: #007aff;
            border-radius: 4px;
            margin-left: 15px;
        }
    }
}

.zdfx-point-tips {
    position: fixed;
    width: 146px;
    height: 102px;
    background: rgba(10, 41, 58, 0.8);
    // position: absolute;
    border-radius: 4px;
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .item {
        margin-top: 5px;
        text-align: left;
    }

    .img {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}
</style>

<style lang="scss">
.nest_controlBox_new {
    z-index: 210 !important;

    .el-progress-circle {
        width: 100% !important;
        height: 100% !important;
    }

    .el-progress__text {
        color: #fff;
        font-size: 14px !important;
    }
}

.zdfx-panel {
    .el-input__inner {
        border: 0 solid rgba(127, 136, 152, 1);
        box-shadow: none !important;
        padding: 1px; //padding必须有值，如果为0，则在倒角处间断
        background-color: #0a293a !important;
        color: #fff;
        text-align: center;
    }

    .el-slider__runway {
        height: 2px;
    }

    .el-slider__bar {
        height: 2px;
    }

    .el-slider__button {
        background-color: #2b72ea;
        border-color: #fff;
        border-width: 1px;
        margin-top: -2px;
    }

    .el-input-group__prepend {
        background-color: #0a293a;
        border: 0;
        color: #2b72ea;
        padding: 0 10px;
    }

    .lonlat-input {
        .el-input__inner {
            text-align: left;
        }
    }
}
</style>
